import { Props as BreakdownMapProps } from 'client/admin/shared/containers/maps/map-breakdown';
import {
  ClientQuestionId,
  QuestionStatus,
  QuestionResults,
  MultipleChoiceResults,
  DiscreteQuestionResults,
  QuestionType,
  ClientQuestionChoiceId,
  QuestionChoice,
  QuestionTypedData_FreeText,
  QuestionTypedData_GridChoice,
  QuestionTypedData_MultipleChoice,
  QuestionTypedData_PointAllocation,
  SavedQuestion,
  SurveyItemType,
  SavedHierarchy,
  QuestionWithExtendedData,
  QuestionTypedData_GridRow,
  ChoiceSetDataDictionary,
  ClientShareableQuestionChoiceSetId,
  QuestionSchedule,
  PositionTypedData,
  PositionType,
} from 'client/shared/core/question';
import {
  ClientQuestionSetId,
  QuestionSetStatus,
} from 'client/shared/core/question-set';
import {
  Brand,
  Case,
  Flavor,
  QuestionSetType,
  unionOfEnum,
  ScaleData,
  ScaleType,
  Language,
  ClientSavedVisualization,
  SimulationType,
  WordCount,
} from 'core';
import {
  Building_Metacondition,
  ClientMetaconditionEphemeralReferenceId,
} from 'client/admin/core/conditions';
import { EmailListOption } from '../share';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { PillTypes } from 'client/shared/components/base/pill';
import { SimulationNodeData } from 'client/shared/core/balancing-act-simulation';

export const MIN_QUESTIONS_REQUIRED = 1;

export type ClientQuestionEphemeralReferenceId = Flavor<
  string,
  'QuestionReferenceId'
>;
export type ClientHierarchyEphemeralReferenceId = Flavor<
  string,
  'HierarchyReferenceId'
>;
export type ClientVisualizationEphemeralReferenceId = Flavor<
  string,
  'VisualizationReferenceId'
>;
export type ClientSimulationEphemeralReferenceId = Flavor<
  string,
  'SimulationReferenceId'
>;
export type ClientQuestionChoiceEphemeralReferenceId = Flavor<
  string,
  'QuestionChoiceReferenceId'
>;
export interface QuestionWithStatusData extends Omit<SavedQuestion, 'status'> {
  readonly statusData: StatusData;
  readonly setId: ClientQuestionSetId;
}

export interface AggregateResults {
  readonly raw: QuestionResults;
  readonly weighted: QuestionResults | null;
}

export interface QuestionWithAggregateResults
  extends Pick<SavedQuestion, 'id' | 'title' | 'isBenchmarkedQuestion'> {
  readonly gridLabel: string | null;
  readonly pubName: string;
  readonly scaleData: ScaleData | null;
  readonly aggregateResults: AggregateResults | null;
  readonly commentsWordCount: readonly WordCount[] | null;
}

export type StatusData = StatusDataWithNoResults | StatusDataWithResults;

export interface StatusDataWithNoResults {
  readonly status:
    | QuestionStatus.DRAFT
    | QuestionStatus.SCHEDULED
    | QuestionStatus.CART;
}

export interface StatusDataWithResults {
  readonly status:
    | QuestionStatus.PUBLISHED
    | QuestionStatus.CLOSED
    | QuestionStatus.ARCHIVED
    | QuestionStatus.HISTORIC_RECORD
    | QuestionStatus.SOFT_DELETED;
  readonly results: QuestionResults;
  readonly stats: QuestionStats;
}

export function statusDataHasResults(
  s: StatusData | StatusDataWithResults
): s is StatusDataWithResults {
  return typeof (s as StatusDataWithResults).results !== 'undefined';
}

export interface QuestionStats {
  readonly commentsCount: number;
  readonly responsesCount: number;
  readonly viewsCount: number;
}

export interface ScheduledQuestion extends QuestionWithStatusData {
  readonly schedule: QuestionSchedule;
}

export function isQuestionActive(
  question: Pick<QuestionWithStatusData, 'statusData'>
): boolean {
  return question.statusData.status === QuestionStatus.PUBLISHED;
}

export interface VotingBreakdownsView {
  readonly id: string;
  readonly breakdowns: readonly VotingBreakdown[];
  readonly description: string;
  readonly label: string;
  readonly map?: BreakdownMapProps;
}

export interface VotingBreakdown {
  readonly label: string;
  readonly results: MultipleChoiceResults;
}

export interface VotingFilter {
  readonly name: string;
  // not applying readonly here to prevent the TS error related to lodash typings
  // "Property x does not exist on type ConcatArray<VotingFilterOption>
  readonly options: readonly VotingFilterOption[];
}

export interface VotingFilterOption {
  readonly id:
    | {
        readonly type: 'verified_filter';
        readonly value: VerifiedFilterOptions;
      }
    | { readonly type: 'uuid'; readonly value: string };
  readonly label: string;
  readonly active: boolean;
}

export enum VerifiedFilterOptions {
  REGISTERED = 'REGISTERED',
  SUBSCRIBERS = 'SUBSCRIBERS',
  VERIFIED = 'VERIFIED',
  ALL = 'ALL',
}

export namespace Report {
  export enum ReportQuestionType {
    QUESTION = 'QUESTION',
    GRID = 'GRID',
  }

  export interface Question {
    readonly id: ClientQuestionId;
    readonly title: string;
    readonly description: string | null;
    readonly maxSelection: number;
    readonly choices: readonly { readonly id: string; readonly text: string }[];
    readonly choicesAreScaled: boolean;
    readonly comments: readonly {
      readonly choiceId?: string;
      readonly comment: string;
    }[];
    readonly stats?: readonly {
      readonly key: string;
      readonly hits: number;
      readonly buckets: readonly {
        readonly key: string;
        readonly hits: number;
        readonly buckets: readonly {
          readonly key: string;
          readonly hits: number;
        }[];
      }[];
    }[];
    readonly results: DiscreteQuestionResults | Case<QuestionType.FREE_TEXT>;
  }

  export interface GridQuestion {
    readonly gridId: string;
    readonly gridLabel: string | null;
    readonly results: QuestionResults;
    readonly questions: readonly Question[];
  }

  export type ReportQuestion =
    | Case<ReportQuestionType.QUESTION, Question>
    | Case<ReportQuestionType.GRID, GridQuestion>;

  export const ReportQuestion = unionOfEnum(ReportQuestionType, {
    ...ReportQuestionType,
  }).andType<ReportQuestion>();
}

export type Building_QuestionChoice = Omit<QuestionChoice, 'id'> & {
  readonly id: ClientQuestionChoiceId | null;
  readonly ephemeralReferenceId: ClientQuestionChoiceEphemeralReferenceId;
};

export interface Building_QuestionChoiceSet_Value {
  readonly choices: readonly Building_QuestionChoice[]; // ordered
  readonly maxSelection: number;
  readonly shareableQuestionChoiceSetId: ClientShareableQuestionChoiceSetId | null;
}

export type Building_GridRow = Omit<
  QuestionTypedData_GridRow,
  'questionId' | 'conditions'
> & {
  readonly questionId: ClientQuestionId | null;
  readonly ephemeralReferenceId: ClientQuestionEphemeralReferenceId;
  readonly metaconditionEphemeralReferenceId: ClientMetaconditionEphemeralReferenceId | null;
};

export type Building_QuestionTypedData_FreeText = QuestionTypedData_FreeText;

export type Building_QuestionTypedData_MultipleChoice = Omit<
  QuestionTypedData_MultipleChoice,
  'choices'
> & {
  readonly choices: readonly Building_QuestionChoice[];
  readonly dataDictionary: ChoiceSetDataDictionary | null;
};
export type Building_QuestionTypedData_PointAllocation = Omit<
  QuestionTypedData_PointAllocation,
  'choices'
> & {
  readonly choices: readonly Building_QuestionChoice[];
  readonly dataDictionary: ChoiceSetDataDictionary | null;
};
export type Building_QuestionTypedData_GridChoice = Omit<
  QuestionTypedData_GridChoice,
  'rows' | 'columns'
> & {
  readonly rows: readonly Building_GridRow[];
  readonly columns: readonly Building_QuestionChoice[];
  readonly dataDictionary: ChoiceSetDataDictionary | null;
};

export type Building_QuestionTypedData =
  | Case<QuestionType.FREE_TEXT, Building_QuestionTypedData_FreeText>
  | Case<QuestionType.MULTIPLE_CHOICE, Building_QuestionTypedData_MultipleChoice>
  | Case<QuestionType.GRID_CHOICE, Building_QuestionTypedData_GridChoice>
  | Case<QuestionType.POINT_ALLOCATION, Building_QuestionTypedData_PointAllocation>;

export type Building_VisualizationTypedData = Case<
  PositionType.VISUALIZATION,
  {
    readonly visualization: ClientSavedVisualization | null;
    readonly label: string | null;
  }
>;

export type Building_SimulationBudgetTypedData = Case<
  SimulationType.BUDGET,
  {
    readonly simulation: SimulationNodeData | null;
  }
>;
export type Building_SimulationHousingTypedData = Case<
  SimulationType.HOUSING,
  {
    readonly simulation: SimulationNodeData | null;
  }
>;
export type Building_SimulationPrioritizeTypedData = Case<
  SimulationType.PRIORITIZE,
  {
    readonly simulation: SimulationNodeData | null;
  }
>;
export type Building_SimulationReceiptTypedData = Case<
  SimulationType.RECEIPT,
  {
    readonly simulation: SimulationNodeData | null;
  }
>;

export type Building_ValidatedVisualizationTypedData = Case<
  PositionType.VISUALIZATION,
  { readonly visualization: ClientSavedVisualization; readonly label: string | null }
>;

export type Building_ValidatedSimulationTypedData = Case<
  | SimulationType.BUDGET
  | SimulationType.HOUSING
  | SimulationType.PRIORITIZE
  | SimulationType.RECEIPT,
  {
    readonly simulation: SimulationNodeData;
  }
>;

export type Building_SurveyItemTypedData =
  | PositionTypedData
  | Building_VisualizationTypedData
  | Building_SimulationBudgetTypedData
  | Building_SimulationHousingTypedData
  | Building_SimulationPrioritizeTypedData
  | Building_SimulationReceiptTypedData
  | Building_QuestionTypedData;

export interface SurveyData {
  readonly surveyItems: readonly Building_SurveyItem[];
  readonly conditions: readonly Building_Metacondition[];
  readonly alternateLanguages: readonly Language[];
}

export const Building_QuestionTypedData = unionOfEnum(QuestionType, {
  ...QuestionType,
}).andType<Building_QuestionTypedData>();

export type Building_Question = Omit<
  QuestionWithExtendedData,
  'id' | 'typedData' | 'conditions' | 'questionNumber'
> & {
  readonly id: ClientQuestionId | null; // In the case of grid nodes, this is the grid hierarchy id
  readonly ephemeralReferenceId: ClientQuestionEphemeralReferenceId;
  readonly typedData: Building_QuestionTypedData;
  readonly metaconditionEphemeralReferenceId: ClientMetaconditionEphemeralReferenceId | null;
};

export type Building_Hierarchy = Omit<SavedHierarchy, 'id' | 'conditions'> & {
  readonly id: string | null;
  readonly ephemeralReferenceId: ClientHierarchyEphemeralReferenceId;
  readonly metaconditionEphemeralReferenceId: ClientMetaconditionEphemeralReferenceId | null;
};

export interface Building_Visualization {
  readonly id: string | null;
  readonly ephemeralReferenceId: ClientVisualizationEphemeralReferenceId;
  readonly metaconditionEphemeralReferenceId: ClientMetaconditionEphemeralReferenceId | null;
  readonly visualizationData: Building_VisualizationTypedData;
}

export interface Building_ValidatedVisualization extends Building_Visualization {
  readonly visualizationData: Building_ValidatedVisualizationTypedData;
}

export interface Building_Simulation {
  readonly id: string | null;
  readonly ephemeralReferenceId: ClientSimulationEphemeralReferenceId;
  readonly metaconditionEphemeralReferenceId: ClientMetaconditionEphemeralReferenceId | null;
  readonly simulationData:
    | Building_SimulationBudgetTypedData
    | Building_SimulationHousingTypedData
    | Building_SimulationReceiptTypedData
    | Building_SimulationPrioritizeTypedData;
}

export interface Building_ValidatedSimulation extends Building_Simulation {
  readonly simulationData: Building_ValidatedSimulationTypedData;
}

export type Building_SurveyItem =
  | {
      readonly type: SurveyItemType.HEADER;
      readonly data: Building_Hierarchy;
    }
  | {
      readonly type: SurveyItemType.VISUALIZATION;
      readonly data: Building_Visualization;
    }
  | {
      readonly type: SurveyItemType.SIMULATION;
      readonly data: Building_Simulation;
    }
  | {
      readonly type: SurveyItemType.QUESTION;
      readonly data: Building_Question;
    };

export type Building_ValidatedSurveyItemData =
  | {
      readonly type: SurveyItemType.HEADER;
      readonly data: Building_Hierarchy;
    }
  | {
      readonly type: SurveyItemType.VISUALIZATION;
      readonly data: Building_ValidatedVisualization;
    }
  | {
      readonly type: SurveyItemType.SIMULATION;
      readonly data: Building_ValidatedSimulation;
    }
  | {
      readonly type: SurveyItemType.QUESTION;
      readonly data: Building_Question;
    };

export type Building_ValidatedSurveyItem = Brand<
  Building_ValidatedSurveyItemData,
  'ValidatedSurveyItem'
>;

export enum AccessSettingType {
  EVERYONE = 'EVERYONE',
  // EMAIL_LIST = 'EMAIL_LIST',
  LINK_ONLY = 'LINK_ONLY',
}

export interface AccessSetting {
  readonly type: AccessSettingType;
  readonly emails: readonly EmailListOption[] | null;
}
interface BaseSet {
  readonly id: ClientQuestionSetId;
  readonly name: string;
  readonly slug: string | null;
  readonly description?: string | null;
  readonly status: QuestionSetStatus;
  readonly questionsCount: number;
  readonly createDate: Date;
  readonly openDate: Date | null;
  readonly closeDate: Date | null;
  readonly allowGuestRespondents: boolean;
  readonly showConversionPrompts: boolean;
  readonly setImageId: string | null;
  readonly accessSetting: AccessSetting;
  readonly polcoManaged: boolean;
  readonly hasBenchmarkedContent: boolean;
  readonly horizontalImageUrl: string | null;

  // Publisher is not something that should obviously be on this
  // very high level QuestionSet object. It is here because it is
  // via the publisher ID that we are able to tell if the question
  // set is a repost or not, and the publisher name to get the original
  // publisher. Repost is much more obviously the sort of top level
  // data we would expect in this object.
  readonly publisher: {
    readonly id: ClientPublishingEntityId;
    readonly name: string;
    readonly slug: string;
  };
  readonly hasAutomaticWeighting: boolean;
  readonly allowMultipleResponses: boolean;
}

export interface LiveEvent extends BaseSet {
  readonly type: QuestionSetType.POLCO_LIVE;

  readonly eventStartDate: Date | null;
  readonly eventEndDate: Date | null;
  readonly liveVideoLink: string | null;
  readonly state: QuestionStatus;
}

export interface PollSet extends BaseSet {
  readonly type: QuestionSetType.SET;
}

export interface Survey extends BaseSet {
  readonly type: QuestionSetType.SURVEY;
  readonly hasVisibleReportTabs: boolean;
}

export interface ContentPost extends BaseSet {
  readonly type: QuestionSetType.CONTENT_POST;
}

export type QuestionSet = LiveEvent | PollSet | Survey | ContentPost;

export interface DataDictionaryEvents {
  readonly setScaleType: (value: ScaleType) => void;
  readonly setScaleThreshold: (value: number) => void;
  readonly clearChoiceSetDataDictionary: () => void;
}

export const questionStatusToText: Record<QuestionStatus, string> = {
  [QuestionStatus.DRAFT]: 'Draft',
  [QuestionStatus.CART]: 'Ready to Publish',
  [QuestionStatus.SCHEDULED]: 'Scheduled',
  [QuestionStatus.PUBLISHED]: 'Published',
  [QuestionStatus.CLOSED]: 'Closed',
  [QuestionStatus.ARCHIVED]: 'Archived',
  [QuestionStatus.SOFT_DELETED]: 'Deleted',
  [QuestionSetStatus.HISTORIC_RECORD]: 'Historic Record',
};

export const questionStatusToPillType: Record<QuestionStatus, PillTypes> = {
  DRAFT: PillTypes.WARNING,
  CART: PillTypes.LIGHT,
  SCHEDULED: PillTypes.PRIMARY,
  PUBLISHED: PillTypes.PRIMARY,
  CLOSED: PillTypes.SECONDARY,
  ARCHIVED: PillTypes.SECONDARY,
  SOFT_DELETED: PillTypes.SECONDARY,
  HISTORIC_RECORD: PillTypes.SECONDARY,
};
