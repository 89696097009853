import { useQueryInfo } from 'client/shared/containers/query';
import * as QueryInfos from 'client/shared/graphql-queries/query-infos';
import { PureQueryOptions, QueryResult } from '@apollo/client';
import {
  ApiDate,
  checkProfileComplete,
  EmailVerificationStatus,
  ExtractGql,
} from 'core';
import {
  AdminRegistrationDecision,
  CurrentAdmin,
  PublishingEntityActivationState,
  PublishingEntityApprovalDecision,
} from 'client/shared/graphql-client/graphql-operations.g';
import { heapIdentify, heapAddUserProperties } from 'client/shared/integrations';
import { featureSettingGqlToClient } from 'client/admin/graphql-util/transforms/feature-settings';
import { AdminData, AdminDataBuilder, AdminPublisher } from '../types';
import { appcuesIdentify } from 'client/shared/integrations/appcues';
import { tagSuperAdminOnMouseflow } from 'client/shared/integrations/mouseflow';
import { errorLogger } from 'client/shared/core/error-handler';
import { PublishingEntityAssetType } from 'client/shared/core/publishing-entity';
import {
  SelectLanguageTextFunction,
  useDefaultLanguage,
  usePdfGeneration,
} from 'client/shared/hooks';
import { privateAdminFromUserAdmin } from 'client/admin/core/user';
import { useTrackingData } from 'client/admin/hooks';
import _ from 'lodash';
import { useDemoPollyFips } from 'client/admin/hooks/use-demo-polly-fips';
import { storeItemAndStatus_gqlToUi } from 'client/admin/core/premium';
import { useMemo } from 'react';

type GqlUser = NonNullable<CurrentAdmin['currentUser']['user']>;
type GqlPrivateAdmin = ExtractGql<NonNullable<GqlUser['admin']>, 'PrivateAdmin'>;
type GqlPublishingEntity = NonNullable<GqlPrivateAdmin['activePublishingEntity']>;

export interface QueryAdminResult {
  readonly data: AdminData;
  readonly refreshQueries: readonly PureQueryOptions[]; // NOTE: *not* readonly b/c of Apollo's typing
}

const POLL_INTERVAL_1_HOUR_MS = 60 * 60 * 1000;

export function useQueryAdminInfo(
  onPublisherLoaded?: (pub: AdminPublisher) => void
): QueryAdminResult {
  const selectLanguageText = useDefaultLanguage();
  const isPdfGeneration = usePdfGeneration();
  const { trackingEmail } = useTrackingData();
  const demoPollyFips = useDemoPollyFips();

  const result = useQueryInfo(QueryInfos.currentAdmin, {
    onCompleted: (d) => {
      const privateAdmin = privateAdminFromUserAdmin<GqlPrivateAdmin>(
        d?.currentUser.user?.admin
      );
      const pub = privateAdmin?.activePublishingEntity;
      if (pub) {
        onPublisherLoaded?.(pubGqlToClient(pub, selectLanguageText));
      }
    },
    pollInterval: POLL_INTERVAL_1_HOUR_MS,
  });

  const adminData = useMemo(
    () =>
      resultToAdminData(result, {
        selectLanguageText,
        isPdfGeneration,
        trackingEmail,
        demoPollyFips,
      }),
    [result, isPdfGeneration, selectLanguageText, trackingEmail, demoPollyFips]
  );

  return {
    data: adminData,
    refreshQueries: [QueryInfos.currentAdmin.refetchInfo({})],
  };
}

function buildUserProperties(
  admin: GqlPrivateAdmin | null,
  trackingEmail: string | null
) {
  return {
    email: trackingEmail ?? admin?.email,
    name: admin?.name,
    superadmin: admin?.superAdmin ?? 'false',
    emailVerificationStatus: admin?.emailVerificationStatus ?? null,
    firstRegistrationDecision: admin?.registrationRequests[0]
      ? admin?.registrationRequests[0].decision
      : null,
    registrationRole: admin?.registrationRole?.displayName ?? null,
    persona: admin?.registrationRole?.persona?.name ?? null,
    activePublisherId: admin?.activePublishingEntity?.id,
    activePublisherSlug: admin?.activePublishingEntity?.slug,
    activePublisherActivationState: admin?.activePublishingEntity?.activationState,
    organizationType: admin?.activePublishingEntity?.organizationType,
    organizationSubType: admin?.activePublishingEntity?.organizationSubtype,
    fipsAreaType: admin?.activePublishingEntity?.fipsArea?.type,
    fipsPopulationSizeSegment:
      admin?.activePublishingEntity?.fipsArea?.populationSizeSegment,
  };
}

export function resultToAdminData(
  r: QueryResult<CurrentAdmin>,
  opts: {
    readonly selectLanguageText: SelectLanguageTextFunction;
    readonly isPdfGeneration: boolean;
    readonly trackingEmail: string | null;
    readonly demoPollyFips: string | null;
  }
): AdminData {
  const { selectLanguageText, isPdfGeneration, trackingEmail } = opts;
  const adminData = AdminDataBuilder();
  const privateAdmin = privateAdminFromUserAdmin<GqlPrivateAdmin>(
    r.data?.currentUser.user?.admin
  );
  if (r.data?.currentUser.user && !isPdfGeneration) {
    initializeUserIntegrations(r.data.currentUser.user, trackingEmail);
  }

  if (r.error) {
    errorLogger.log(`Error with admin data: ${r.error}`);
    return adminData.ERROR();
  } else if (r.loading || !r.data || !r.data.currentUser) {
    return adminData.LOADING();
  } else if (!r.data.currentUser.user) {
    return adminData.NOT_LOGGED_IN();
  } else if (!privateAdmin) {
    const user = r.data.currentUser.user;
    return adminData.NOT_ADMIN({
      email: null,
      name: undefined,
      userId: user.id,
    });
  } else if (adminIsRejected(privateAdmin)) {
    return adminData.REJECTED();
  } else if (!privateAdmin?.activePublishingEntity) {
    // If user has no active pub, but they have a registration missing a pub
    const registrations = privateAdmin?.registrationRequests;
    const mostRecentRegistration = _.maxBy(
      registrations,
      (reg) => reg.createdTime.raw
    );
    if (mostRecentRegistration && mostRecentRegistration.publishingEntity === null) {
      return adminData.PENDING_PUB_ADMIN({
        registrationId: mostRecentRegistration?.id,
      });
    }
    return adminData.NO_PUBLISHER_PERMISSIONS();
  } else {
    // We only get here if they passed all the checks above.
    // The admin should now be able to use the site, but we need additional states to control what they can do
    const { user } = r.data.currentUser;
    const adminDataValues = {
      admin: {
        ...privateAdmin,
        createdAt: ApiDate.fromApi(privateAdmin.createDate),
        activePublishingEntity: pubGqlToClient(
          privateAdmin.activePublishingEntity,
          selectLanguageText
        ),
        demoPollyUrl: privateAdmin.demoPollyUrl
          ? opts.demoPollyFips
            ? `${privateAdmin.demoPollyUrl.split('fips=')[0]}fips=${
                opts.demoPollyFips
              }`
            : privateAdmin.demoPollyUrl
          : null,
      },
      email: privateAdmin.email,
      userId: user.id,
    };
    const activePub = privateAdmin.activePublishingEntity;
    // If they have an active pub, the first thing they need to do is complete the pub's profile
    if (activePub) {
      const logoUrl =
        activePub.assets.find((i) => i.type === PublishingEntityAssetType.PRIMARY)
          ?.url ?? null;
      const description = selectLanguageText(activePub.description);

      const isProfileComplete = checkProfileComplete({
        logoUrl,
        description,
      });

      if (!isProfileComplete) {
        return adminData.INCOMPLETE_PUB_PROFILE_ADMIN(adminDataValues);
      }

      if (
        privateAdmin?.emailVerificationStatus ===
        EmailVerificationStatus.NEEDS_VERIFICATION
      ) {
        return adminData.PENDING_EMAIL_VERIFICATION_ADMIN(adminDataValues);
      }
    }
    // If they are on a pub with a completed profile, they need approval to publish
    if (!adminIsApproved(privateAdmin)) {
      return adminData.PENDING_APPROVAL_ADMIN(adminDataValues);
    }
    // Should not happen, but if they are approved on a pub with a complete profile, the pub needs to be activated
    if (
      activePub &&
      activePub.activationState === PublishingEntityActivationState.NOT_ACTIVATED
    ) {
      return adminData.PENDING_PUB_ACTIVATION_ADMIN(adminDataValues);
    }

    // They should have access to use the site in full
    return adminData.ADMIN(adminDataValues);
  }
}

function initializeUserIntegrations(
  currentUser: GqlUser,
  trackingEmail: string | null
) {
  const privateAdmin = privateAdminFromUserAdmin<GqlPrivateAdmin>(currentUser.admin);

  // For demo environment with shared account, make IDs unique
  const trackingId = `${currentUser.id}${trackingEmail ? `-${trackingEmail}` : ''}`;
  heapIdentify(trackingId);
  const userProps = buildUserProperties(privateAdmin, trackingEmail);
  heapAddUserProperties(userProps);
  appcuesIdentify(trackingId, userProps);

  if (privateAdmin?.superAdmin) {
    tagSuperAdminOnMouseflow();
  }
}

function adminIsApproved(admin: GqlPrivateAdmin): boolean {
  if (!admin.activePublishingEntity) {
    return false;
  }
  // If publishing entity is not approved, then admin is also not approved to publish
  if (
    admin.activePublishingEntity.approvalDecision ===
    PublishingEntityApprovalDecision.NOT_APPROVED
  ) {
    return false;
  }
  if (admin.superAdmin) {
    return true;
  }
  // If admin registered to be added to publishing entity, decision must be approved
  const activePublisherRegistration = admin.registrationRequests.find(
    (request) =>
      request.publishingEntity &&
      request.publishingEntity.id === admin.activePublishingEntity?.id
  );
  if (
    !activePublisherRegistration ||
    activePublisherRegistration.decision === AdminRegistrationDecision.APPROVED ||
    activePublisherRegistration.decision ===
      AdminRegistrationDecision.APPROVED_AUTOMATIC
  ) {
    return true;
  }
  return false;
}

function adminIsRejected(admin: GqlPrivateAdmin): boolean {
  // If admin does not have access to any pubs and has no pending registration requests, we assume they are in a rejected state
  if (!admin.activePublishingEntity && admin.registrationRequests.length > 0) {
    return admin.registrationRequests.every(
      (request) => request.decision === AdminRegistrationDecision.REJECTED
    );
  }
  return false;
}

function pubGqlToClient(
  gql: GqlPublishingEntity,
  selectLanguageText: SelectLanguageTextFunction
): AdminPublisher {
  return {
    ...gql,
    name: selectLanguageText(gql.name),
    description: selectLanguageText(gql.description),
    postedBy: selectLanguageText(gql.postedBy),
    featureSettings: featureSettingGqlToClient(gql.featureSettings ?? []),
    storeTiers: (gql.storeTiers ?? []).map(storeItemAndStatus_gqlToUi),
    fips: gql.fipsArea?.id ?? null,
  };
}
