import {
  Dropdown,
  MaterialIconName,
  Well,
  WellType,
} from 'client/shared/components/base';
import { TrackAreaMode, VisualizationType, visualizationTypeLabel } from 'core';
import React from 'react';
import { DOMAIN_CHART_CONTAINER_COPY } from './copy';
import './styles.scss';
import { PredictiveDataToggle } from 'client/shared/components/domain-community-statistics-card';
const COPY = {
  myCommunity: 'My Community',
};
const baseClass = 'pn-index-scores-chart-container';

interface Props {
  readonly areaMode: TrackAreaMode;
  readonly chartType: VisualizationType;
  readonly setChartType: React.Dispatch<
    React.SetStateAction<VisualizationType | null>
  >;
  readonly showMyCommunity: boolean;
  readonly setPredictiveDataEnabled?: React.Dispatch<React.SetStateAction<boolean>>;
  readonly predictiveDataEnabled?: boolean;
  readonly hasArPredictiveData?: boolean;
  readonly hasGpalPredictiveData?: boolean;
}

export const IndexScoreChartContainer: React.FC<Props> = (p) => {
  const useChartType = isSupportedChartType(p.chartType)
    ? p.chartType
    : VisualizationType.HISTOGRAM;
  return (
    <div className={`${baseClass} mt-3 d-flex flex-column`}>
      {p.areaMode !== TrackAreaMode.NATIONAL && (
        <div className="d-flex justify-content-between">
          <Dropdown
            className="no-show-in-image"
            displayInline
            keySelect={(chartType) => `${chartType}`}
            labelIconSelect={(chartType) => getIconForChartType(chartType)}
            labelSelect={(chartType) => visualizationTypeLabel[chartType]}
            menuClassName="rounded"
            onChange={(chartType) => p.setChartType(chartType)}
            options={Object.values(VisualizationType).filter(isSupportedChartType)}
            prompt="Chart Type"
            promptIcon={getIconForChartType(useChartType)}
            toggleClassName="rounded"
            value={useChartType}
          />
          {p.showMyCommunity && (
            <div className="d-flex h-100 align-items-center">
              <div
                className={`bg-liberty border rounded-circle ${baseClass}-color-key`}
              />
              <span className="font-size-sm px-1">{COPY.myCommunity}</span>
            </div>
          )}
        </div>
      )}
      {p.chartType === VisualizationType.LINE && (
        <PredictiveDataToggle
          handlePredictionToggle={() =>
            p.setPredictiveDataEnabled?.(!p.predictiveDataEnabled)
          }
          hasPredictiveData={!!p.hasArPredictiveData || !!p.hasGpalPredictiveData}
          showPredictiveData={p.predictiveDataEnabled}
        />
      )}

      <div className="my-2">{p.children}</div>

      <Well
        className="mb-0"
        noTopMargin
        type={WellType.TEXT_BLOCK_WHITE_WITH_BORDER}
      >
        {useChartType === VisualizationType.HISTOGRAM
          ? DOMAIN_CHART_CONTAINER_COPY.histogramWell
          : DOMAIN_CHART_CONTAINER_COPY.lineChartWell}
      </Well>
    </div>
  );
};

function isSupportedChartType(chartType: VisualizationType): boolean {
  switch (chartType) {
    case VisualizationType.HISTOGRAM:
      return true;
    case VisualizationType.LINE:
      return true;
    case VisualizationType.MAP:
    default:
      return false;
  }
}

function getIconForChartType(chartType: VisualizationType): MaterialIconName {
  switch (chartType) {
    case VisualizationType.HISTOGRAM:
      return MaterialIconName.INSERT_CHART_OUTLINED;
    case VisualizationType.LINE:
    case VisualizationType.PROGRESS:
      return MaterialIconName.SHOW_CHART;
    case VisualizationType.MAP:
      return MaterialIconName.MAP;
  }
}
