/* eslint no-param-reassign: "off" */
import { triggerSocialProviderInitializationCallbacks } from '../util';

let fbInitialized = false;

export function fbIsInitialized() {
  return fbInitialized;
}

export function isFacebookBrowser() {
  const ua =
    navigator.userAgent ||
    navigator.vendor ||
    ((window as any).opera as string) ||
    '';
  return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1;
}

export function fbInit(appId: string) {
  (window as any).fbAsyncInit = function () {
    FB.init({
      appId,
      xfbml: true,
      version: 'v7.0',
    });
  };
  fbInitialized = true;
  triggerSocialProviderInitializationCallbacks();

  (function (d, s, id) {
    const fjs = d.getElementsByTagName(s)[0];
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement(s) as HTMLScriptElement;
    js.id = id;
    js.src = 'https://connect.facebook.net/en_US/sdk.js';
    if (fjs.parentNode) {
      fjs.parentNode.insertBefore(js, fjs);
    }
  })(document, 'script', 'facebook-jssdk');
}

export function fbShare(
  // params: facebook.ShareDialogParams,
  // callback: (response: facebook.ShareDialogResponse) => void
  url: string
) {
  if (FB && FB.ui) {
    FB.ui({ method: 'share', href: url }, (_r) => {});
  }
}
