import {
  CustomLayer,
  PointSymbolProps,
  PointTooltipProps,
  ResponsiveLine,
  Serie,
} from '@nivo/line';
import _ from 'lodash';
import React from 'react';
import './styles.scss';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';
import { formatIndicatorValue } from 'client/shared/core/performance-data';
import { AnalyticsValueType, DateLevel, StatisticType, TrackVariable } from 'core';
import moment from 'moment';
import { useId } from 'react-use-id-hook';
import { ExpandedPoint, TrackLineChartTooltip } from './tooltip';
import { useLineChartScale } from './use-line-chart-scale';
import { PredictiveModelType } from '@polco-us/types';
import { useMediaQuery, useTheme } from '@mui/material';

interface Props {
  readonly variable: Pick<
    TrackVariable,
    'id' | 'name' | 'valueType' | 'direction' | 'dateLevel' | 'statisticType'
  >;
  readonly data: readonly DomainIndicatorsFipsAreaRow[];
  readonly fipsAreaVisibility: Record<string, boolean>;
  readonly hoverLine: {
    readonly fips: string;
    readonly modelType: PredictiveModelType;
  } | null;
  readonly setHoverLine: React.Dispatch<
    React.SetStateAction<{
      readonly fips: string;
      readonly modelType: PredictiveModelType;
    } | null>
  >;
  readonly goalLine?: number;
  readonly predictiveDataEnabled?: boolean;
  readonly hidePlaceName?: boolean;
}

const baseClass = 'pn-domain-indicators-group-line-chart';

export const TrackLineChart: React.FC<Props> = (p) => {
  const { setHoverLine, hoverLine, predictiveDataEnabled: forecastEnabled } = p;
  const tooltipId = useId();
  const theme = useTheme();
  const isSmallBreakpoint = useMediaQuery(theme.breakpoints.down('xs'));
  const { yAxisValues, yAxisScaleProps, xAxisValues, xAxisScaleProps } =
    useLineChartScale({
      data: p.data,
      goalLine: p.goalLine,
      variable: p.variable,
      forecastEnabled,
      isSmallBreakpoint,
    });
  const pointHoverEvent = React.useCallback(
    (point: ExpandedPoint) => {
      setHoverLine({ fips: point.data.fips, modelType: point.data.modelType });
    },
    [setHoverLine]
  );
  const mouseOutEvent = React.useCallback(() => {
    setHoverLine(null);
  }, [setHoverLine]);

  const { rowsShown, colorsShown } = React.useMemo<{
    readonly rowsShown: Serie[];
    readonly colorsShown: string[];
  }>(() => {
    const rows = transformData(p.data, p.variable.dateLevel, !!forecastEnabled);
    const visibleRows = rows.filter((row) => p.fipsAreaVisibility[row.fips]);
    const colors = visibleRows.map((row) => row.color);
    return {
      rowsShown: visibleRows,
      colorsShown: colors,
    };
  }, [p.data, p.variable, p.fipsAreaVisibility, forecastEnabled]);
  const pointSymbolFn = React.useCallback(
    (point: Readonly<PointSymbolProps>) => {
      const { fips, modelType } = hoverLine ? hoverLine : {};
      return (point.datum.fips === fips &&
        point.datum.modelType === modelType &&
        !point.datum.omitPoint) ||
        point.datum.isSinglePoint ? (
        <circle fill={point.color} r={7} stroke={'black'} />
      ) : null;
    },
    [hoverLine]
  );
  const dataByDataAreaFips = React.useMemo(
    () => _.keyBy(p.data, ({ dataAreaFips }) => dataAreaFips),
    [p.data]
  );

  const markers = React.useMemo(() => {
    return _.compact([
      p.goalLine
        ? ({
            axis: 'y',
            value: p.goalLine,
            lineStyle: {
              stroke: '#B72E1C',
              strokeWidth: 3,
              strokeDasharray: 12,
            },
          } as const)
        : null,
    ]);
  }, [p.goalLine]);
  const tooltipFn = React.useCallback(
    (tooltipProps: React.PropsWithChildren<PointTooltipProps>) => {
      return (
        <TrackLineChartTooltip
          dataByDataAreaFips={dataByDataAreaFips}
          goalLine={p.goalLine}
          id={tooltipId}
          tooltipProps={tooltipProps}
          variable={p.variable}
        />
      );
    },
    [p.variable, tooltipId, p.goalLine, dataByDataAreaFips]
  );

  //TO-DO create data unavailable component if min or max year is unavailable
  if (!xAxisValues.length) {
    return <></>;
  }

  return (
    <div>
      <div className={`${baseClass}-full-chart mb-3`}>
        <ResponsiveLine
          axisBottom={{
            tickValues: xAxisValues,
            format: (tick) => formatXAxisValue(tick, p.variable.dateLevel),
            tickSize: 0,
            tickPadding: 10,
          }}
          axisLeft={{
            tickValues: yAxisValues,
            format: (value: number) =>
              formatValueByStatisticType({
                value,
                valueType: p.variable.valueType,
                statisticType: p.variable.statisticType,
                compactDisplay: true,
              }),
            tickSize: 0,
            tickPadding: 20,
          }}
          colors={colorsShown}
          data={rowsShown}
          enableGridX={false}
          enableGridY={true}
          gridYValues={yAxisValues}
          isInteractive
          layers={[
            'grid',
            'markers',
            'axes',
            'areas',
            DashedSolidLine,
            'points',
            'slices',
            'mesh',
          ]}
          lineWidth={3}
          margin={{ top: 30, right: 40, bottom: 50, left: 65 }}
          markers={markers}
          onMouseLeave={mouseOutEvent}
          onMouseMove={pointHoverEvent}
          pointSize={0}
          pointSymbol={pointSymbolFn}
          theme={{
            grid: {
              line: {
                stroke: '#c6cbc8',
                strokeWidth: 1,
                strokeDasharray: '7 5',
              },
            },

            axis: {
              ticks: {
                text: {
                  fontSize: 14,
                  fontFamily: 'General Grotesque',
                  fill: '#464b47',
                },
              },
            },
          }}
          tooltip={tooltipFn}
          useMesh
          xScale={xAxisScaleProps}
          yScale={yAxisScaleProps}
        />
      </div>
      <TrackLineChartLegend
        hasArPreditiveData={p.data.some(
          (d) => d.trackAreaData.arPredictiveData.length > 0
        )}
        hasGpalPreditiveData={p.data.some(
          (d) => d.trackAreaData.gpalPredictiveData.length > 0
        )}
        showPredictive={p.predictiveDataEnabled}
      />
    </div>
  );
};

function transformData(
  rows: readonly DomainIndicatorsFipsAreaRow[],
  dateLevel: DateLevel,
  includePredictiveData: boolean

  // Disabling readonly rule because charting library does not like readonly arrays
  // eslint-disable-next-line functional/prefer-readonly-type
): Serie[] {
  return rows.flatMap((row) => {
    const latestRealData = _(row.trackAreaData.performanceData).maxBy((datum) =>
      datum.recordedAt.getTime()
    );

    return [
      {
        data: row.trackAreaData.performanceData,
        modelType: PredictiveModelType.REAL,
        color: row.color,
        style: 'solid',
      },
      {
        data: row.trackAreaData.arPredictiveData.concat(
          latestRealData ? [latestRealData] : []
        ),
        modelType: PredictiveModelType.AR_PREDICTIVE,
        color: '#0EAD00',
        style: 'dashed',
      },
      {
        data: row.trackAreaData.gpalPredictiveData.concat(
          latestRealData ? [latestRealData] : []
        ),
        modelType: PredictiveModelType.GPAL_PREDICTIVE,
        color: '#E26F03',
        style: 'dashed',
      },
    ]
      .filter(
        (dataset) =>
          includePredictiveData || dataset.modelType === PredictiveModelType.REAL
      )
      .map(({ data, modelType, color, style }) => ({
        id: `${row.dataAreaFips}+${modelType}`,
        fips: row.dataAreaFips,
        color,
        style,
        modelType,
        data: data
          .sort((a, b) => a.recordedAt.getTime() - b.recordedAt.getTime())
          .map((datum, idx) => ({
            x:
              dateLevel === DateLevel.YEAR
                ? datum.recordedAt.getUTCFullYear()
                : datum.recordedAt,
            y: datum.value,
            isSinglePoint:
              data.length === 1 && modelType === PredictiveModelType.REAL,
            fips: row.dataAreaFips,
            modelType,
            omitPoint: modelType !== PredictiveModelType.REAL && idx === 0,
          })),
      }));
  });
}

const DashedSolidLine: CustomLayer = ({ series, lineGenerator, xScale, yScale }) => {
  return series.map(({ id, data, color, style }) => (
    <path
      // @ts-ignore
      d={lineGenerator(
        data.map((d) => ({
          // @ts-ignore
          x: xScale(d.data.x),
          // @ts-ignore
          y: yScale(d.data.y),
        }))
      )}
      fill="none"
      key={id}
      stroke={color}
      style={
        style === 'dashed'
          ? {
              strokeDasharray: '11, 8',
              strokeWidth: 3,
            }
          : {
              strokeWidth: 3,
            }
      }
    />
  ));
};

export function formatXAxisValue(
  value: number | string | Date,
  dateLevel: DateLevel
) {
  switch (dateLevel) {
    case DateLevel.MONTH:
      return moment(value).utc().format('MMM YYYY');
    case DateLevel.YEAR:
      return value;
  }
}

export function formatValueByStatisticType(args: {
  readonly value: number;
  readonly statisticType: StatisticType;
  readonly valueType: AnalyticsValueType;
  readonly compactDisplay?: boolean;
}) {
  const { value, statisticType, valueType, compactDisplay } = args;
  switch (statisticType) {
    case StatisticType.INDEX:
      return _.round(value).toString();
    case StatisticType.INDICATOR:
    case StatisticType.SENTIMENT_VALUE:
      return formatIndicatorValue(value, valueType, 2, false, compactDisplay);
  }
}

TrackLineChart.displayName = 'TrackLineChart';

export const TrackLineChartLegend: React.FC<{
  readonly showPredictive?: boolean;
  readonly hasArPreditiveData: boolean;
  readonly hasGpalPreditiveData: boolean;
}> = (p) => {
  return p.showPredictive && (p.hasArPreditiveData || p.hasGpalPreditiveData) ? (
    <div className="d-flex gap-5 mb-2">
      <div className="d-flex align-items-center px-4 py-2">
        <svg className="pn-domain-indicators-group-line-chart-tooltip mr-1">
          <circle cx={8} cy={8} fill={'#4E61BA'} r={8} />
        </svg>
        <p className="font-size-sm">My Community Data</p>
      </div>
      {p.hasArPreditiveData && (
        <div className="d-flex align-items-center px-4 py-2">
          <svg className="pn-domain-indicators-group-line-chart-tooltip mr-1">
            <circle cx={8} cy={8} fill={'#0EAD00'} r={8} />
          </svg>
          <p className="font-size-sm">AR(1) Predictive Model </p>
        </div>
      )}
      {p.hasGpalPreditiveData && (
        <div className="d-flex align-items-center px-4 py-2">
          <svg className="pn-domain-indicators-group-line-chart-tooltip mr-1">
            <circle cx={8} cy={8} fill={'#E26F03'} r={8} />
          </svg>
          <p className="font-size-sm">GPAL Predictive Model </p>
        </div>
      )}
    </div>
  ) : null;
};
