import * as React from 'react';
import './styles.scss';
import { Box, Tab, Tabs } from '@mui/material';
import { MaterialIcon, MaterialIconName } from '../material-icon';
import scrollIntoView from 'scroll-into-view-if-needed';
import uuid from 'uuid';
import { PolcoColor } from 'client/shared/core/colors-class';

export interface TabBarItem<T = string> {
  readonly key: T;
  readonly label: string;
  readonly icon?: MaterialIconName;
  readonly badge?: number;
}

export interface TabBarProps<T = string> {
  readonly tabs: ReadonlyArray<TabBarItem<T>>;
  readonly selected: TabBarItem<T>;
  readonly className?: string;
  readonly tabSelected: (tab: TabBarItem<T>) => void;
}

export const baseClass = 'pn-tab-bar';

export function TabBar<T extends string = string>(props: TabBarProps<T>) {
  const { current: tabBarId } = React.useRef(uuid.v4());
  const initialRenderRef = React.useRef(true);
  const [selectedTabLabel, setSelectedTabLabel] = React.useState(
    props.selected.label
  );

  React.useEffect(
    () => {
      if (initialRenderRef.current) {
        initialRenderRef.current = false;
        // We dont want to do anything because this is the very first render. We only want to scroll around when the tab changes,
        // not the first time the tab load
        return;
      }

      const tabBar = document.getElementById(tabBarId);
      if (tabBar) {
        scrollIntoView(tabBar, {
          scrollMode: 'if-needed',
          behavior: 'smooth',
        });
      }

      setSelectedTabLabel(props.selected.label);
    },
    [
      tabBarId,
      props.selected.label,
    ] /** This triggers anytime the tab change due to internal cause (i.e. a tab was clicked), or an external cause (i.e. the url changed) */
  );

  const tabs = props.tabs.map((tab, i) => {
    return (
      <Tab
        className={`text-capitalize px-3`}
        key={i}
        label={
          <div className="d-flex align-items-center">
            {tab.icon && <MaterialIcon className="mr-1" icon={tab.icon} />}
            {tab.label}
            {tab.badge !== undefined && (
              <div className={`${baseClass}-badge`}>{tab.badge}</div>
            )}
          </div>
        }
        // Register as Tab.onClick instead of Tabs.onChange so
        // we can trigger callback when an already-selected tab is clicked
        onClick={() => props.tabSelected(tab)}
        sx={{
          fontWeight: 'bold',
          textTransform: 'none',
        }}
        tabIndex={0}
        wrapped={false}
      />
    );
  });
  return (
    <Box
      className={`${baseClass} ${props.className ?? ''} mb-2`}
      id={tabBarId}
      sx={{
        borderBottom: `1px solid ${new PolcoColor('Gray-20').hex}`,
        // Cover up scrollable content underneath when we use this as a sticky header
        backgroundColor: '#fff',
      }}
    >
      <Tabs
        scrollButtons="auto"
        value={props.tabs.findIndex((tab) => tab.key === props.selected.key)}
        variant="scrollable"
      >
        {tabs}
      </Tabs>

      <h2 className="sr-only">{selectedTabLabel}</h2>
    </Box>
  );
}
TabBar.displayName = 'TabBar';
