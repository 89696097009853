import AdminPublishingWizardShare from 'client/shared/graphql-queries/AdminPublishingWizardShare.graphql';
import AdminPublishingWizardSchedule from 'client/shared/graphql-queries/AdminPublishingWizardSchedule.graphql';
import AdminPublishingEntityAiAssistantInteractions from 'client/shared/graphql-queries/AdminPublishingEntityAiAssistantInteractions.graphql';
import CurrentAiAssistantSessionConversation from 'client/shared/graphql-queries/AdminCurrentAiAssistantSessionConversation.graphql';
import CurrentAiAssistantSessionStatus from 'client/shared/graphql-queries/AdminCurrentAiAssistantSessionStatus.graphql';
import CurrentAiAssistantQueryStatus from 'client/shared/graphql-queries/AdminCurrentAiAssistantQueryStatus.graphql';
import CurrentActiveFlags from 'client/shared/graphql-queries/CurrentActiveFlags.graphql';
import OpenIdea from 'client/shared/graphql-queries/OpenIdea.graphql';
import AdminOpenBalancingActTaxReceipt from 'client/shared/graphql-queries/AdminOpenBalancingActTaxReceipt.graphql';
import AdminOpenBalancingActSimulation from 'client/shared/graphql-queries/AdminOpenBalancingActSimulation.graphql';
import AdminTrackUpsellPerformanceDataVariable from 'client/shared/graphql-queries/AdminTrackUpsellPerformanceDataVariable.graphql';
import AdminPublishingEntityQualityImportanceMatrix from 'client/shared/graphql-queries/AdminPublishingEntityQualityImportanceMatrix.graphql';
import AdminComparisonGroupFipsShapes from 'client/shared/graphql-queries/AdminComparisonGroupFipsShapes.graphql';
import AdminComparisonGroupDomainTrackVariables from 'client/shared/graphql-queries/AdminComparisonGroupDomainTrackVariables.graphql';
import AdminComparisonGroupDomainIndicators from 'client/shared/graphql-queries/AdminComparisonGroupDomainIndicators.graphql';
import AdminComparisonGroupDomainIndexScores from 'client/shared/graphql-queries/AdminComparisonGroupDomainIndexScores.graphql';
import AdminComparisonGroupTrackOverview from 'client/shared/graphql-queries/AdminComparisonGroupTrackOverview.graphql';
import AdminPublishingEntityPerformanceDataByDomain from 'client/shared/graphql-queries/AdminPublishingEntityPerformanceDataByDomain.graphql';
import AdminPublishingEntityPerformanceData from 'client/shared/graphql-queries/AdminPublishingEntityPerformanceData.graphql';
import AdminLibraryContentSearch from 'client/shared/graphql-queries/AdminLibraryContentSearch.graphql';
import SuperAdminExportTranslations from 'client/shared/graphql-queries/SuperAdminExportTranslations.graphql';
import AdminLoadEmailListWithEmails from 'client/shared/graphql-queries/AdminLoadEmailListWithEmails.graphql';
import AdminLoadPublisherShareConfigurations from 'client/shared/graphql-queries/AdminLoadPublisherShareConfigurations.graphql';
import RespondentProfile from 'client/shared/graphql-queries/RespondentProfile.graphql';
import respondentActiveSubscriptionsForIdea from 'client/shared/graphql-queries/RespondentActiveSubscriptionsForIdea.graphql';
import RespondentSubscriptions from 'client/shared/graphql-queries/RespondentSubscriptions.graphql';
import RespondentPublisherProfile from 'client/shared/graphql-queries/RespondentPublisherProfile.graphql';
import RespondentPolcoLiveBase from 'client/shared/graphql-queries/RespondentPolcoLiveBase.graphql';
import RespondentOverviewPage from 'client/shared/graphql-queries/RespondentOverview.graphql';
import RespondentOutcomePage from 'client/shared/graphql-queries/RespondentOutcome.graphql';
import RespondentVotingPage from 'client/shared/graphql-queries/RespondentVotingPage.graphql';
import AdminCopyContentSetData from 'client/shared/graphql-queries/AdminCopyContentSetData.graphql';
import RespondentFeed from 'client/shared/graphql-queries/RespondentFeed.graphql';
import AdminReadyToShareQuestions from 'client/shared/graphql-queries/AdminReadyToShareQuestions.graphql';
import AdminContentReport from 'client/shared/graphql-queries/AdminContentReport.graphql';
import AdminNcsFacetSummaries from 'client/shared/graphql-queries/AdminNcsFacetSummaries.graphql';
import AdminMenuItems from 'client/shared/graphql-queries/AdminMenuItems.graphql';
import SuperAdminPublishingEntityConfigurationDashboard from 'client/shared/graphql-queries/SuperAdminPublishingEntityConfigurationDashboard.graphql';
import AdminLoadQuestionSetCreationData from 'client/shared/graphql-queries/AdminLoadQuestionSetCreationData.graphql';
import AdminLoadQuestionSetImages from 'client/shared/graphql-queries/AdminLoadQuestionSetImages.graphql';
import AdminContentSetRespondentsStatsLocation from 'client/shared/graphql-queries/AdminContentSetRespondentsStatsLocation.graphql';
import AdminContentSetRespondentStatsWithRepresentivityData from 'client/shared/graphql-queries/AdminContentSetRespondentStatsWithRepresentivityData.graphql';
import AdminContentSetRespondentsStats from 'client/shared/graphql-queries/AdminContentSetRespondentsStats.graphql';
import AdminPublishingEntityComparisonGroups from 'client/shared/graphql-queries/AdminPublishingEntityComparisonGroups.graphql';
import AdminPublishingEntityIdeaSubmissions from 'client/shared/graphql-queries/AdminPublishingEntityIdeaSubmissions.graphql';
import AdminPublishingEntityRoles from 'client/shared/graphql-queries/AdminPublishingEntityRoles.graphql';
import AdminPublishingEntityFeatureSettings from 'client/shared/graphql-queries/AdminPublishingEntityFeatureSettings.graphql';
import AdminPublishingEntitySettings from 'client/shared/graphql-queries/AdminPublishingEntitySettings.graphql';
import AdminPublishingEntityOutreach from 'client/shared/graphql-queries/AdminPublishingEntityOutreach.graphql';
import PublishingEntityCreationForm from 'client/shared/graphql-queries/PublishingEntityCreationForm.graphql';
import PublishingEntityById from 'client/shared/graphql-queries/PublishingEntityById.graphql';
import OpenQuestionWithComments from 'client/shared/graphql-queries/AdminOpenQuestionWithComments.graphql';
import AdminOpenQuestionStructure from 'client/shared/graphql-queries/AdminOpenQuestionStructure.graphql';
import AdminOpenQuestionAggregateResults from 'client/shared/graphql-queries/AdminOpenQuestionAggregateResults.graphql';
import AdminOpenQuestion from 'client/shared/graphql-queries/AdminOpenQuestion.graphql';
import AdminOpenQuestionSetBase from 'client/shared/graphql-queries/AdminOpenQuestionSetBase.graphql';
import AdminOpenQuestionBase from 'client/shared/graphql-queries/AdminOpenQuestionBase.graphql';
import AdminLiveQuestionResults from 'client/shared/graphql-queries/AdminLiveQuestionResults.graphql';
import AdminLiveQuestionSetStructure from 'client/shared/graphql-queries/AdminLiveQuestionSetStructure.graphql';
import AdminOpenPublishingEntityGeoViewById from 'client/shared/graphql-queries/AdminOpenPublishingEntityGeoViewById.graphql';
import AdminPublishingEntityBenchmarkSurveys from 'client/shared/graphql-queries/AdminPublishingEntityBenchmarkSurveys.graphql';
import AdminOpenQuestionSetBenchmarkOverviewStructure from 'client/shared/graphql-queries/AdminOpenQuestionSetBenchmarkOverviewStructure.graphql';
import AdminOverviewParticipants from 'client/shared/graphql-queries/AdminOverviewParticipants.graphql';
import AdminOpenQuestionSetCrosstabOptions from 'client/shared/graphql-queries/AdminOpenQuestionSetCrosstabOptions.graphql';
import AdminOpenQuestionSetTopicDetailsQualityAggregateResult from 'client/shared/graphql-queries/AdminOpenQuestionSetTopicDetailsQualityAggregateResult.graphql';
import AdminOpenQuestionSetTopicsQualityAggregateResult from 'client/shared/graphql-queries/AdminOpenQuestionSetTopicsQualityAggregateResult.graphql';
import AdminOpenQuestionSetTopicDetailsQualityImportance from 'client/shared/graphql-queries/AdminOpenQuestionSetTopicDetailsQualityImportance.graphql';
import AdminOpenQuestionSetTopicsQualityImportance from 'client/shared/graphql-queries/AdminOpenQuestionSetTopicsQualityImportance.graphql';
import AdminOpenQuestionSetStructure from 'client/shared/graphql-queries/AdminOpenQuestionSetStructure.graphql';
import AdminOpenVisualization from 'client/shared/graphql-queries/AdminOpenVisualization.graphql';
import AdminOpenQuestionSetGridAggregateResults from 'client/shared/graphql-queries/AdminOpenQuestionSetGridAggregateResults.graphql';
import AdminOpenFullTrendsQuestionSetAggregateResults from 'client/shared/graphql-queries/AdminOpenFullTrendsQuestionSetAggregateResult.graphql';
import AdminOpenQuestionSetAggregateResults from 'client/shared/graphql-queries/AdminOpenQuestionSetAggregateResults.graphql';
import OpenContentSet from 'client/shared/graphql-queries/AdminOpenQuestionSet.graphql';
import VotingPage from 'client/shared/graphql-queries/Voting.graphql';
import AdminPublishingEntityVisualizations from 'client/shared/graphql-queries/AdminPublishingEntityVisualizations.graphql';
import AdminBenchmarkReportTopicRankings from 'client/shared/graphql-queries/AdminBenchmarkReportTopicRankings.graphql';
import AdminBenchmarkReportTopicDisparities from 'client/shared/graphql-queries/AdminBenchmarkReportTopicDisparities.graphql';
import AdminBenchmarkReportCrosstab from 'client/shared/graphql-queries/AdminBenchmarkReportCrosstab.graphql';
import AdminQuestionResultsAdvancedMap from 'client/shared/graphql-queries/AdminQuestionResultsAdvancedMap.graphql';
import AdminQuestionResultsAdvanced from 'client/shared/graphql-queries/AdminQuestionResultsAdvanced.graphql';
import AdminQuestionResultsHeader from 'client/shared/graphql-queries/AdminQuestionResultsHeader.graphql';
import AdminQuestionStats from 'client/shared/graphql-queries/AdminQuestionStats.graphql';
import AdminStoreItem from 'client/shared/graphql-queries/AdminStoreItem.graphql';
import AdminStore from 'client/shared/graphql-queries/AdminStore.graphql';
import AdminPublishingEntityContentSetNamesAndShortUrls from 'client/shared/graphql-queries/AdminPublishingEntityContentSetNamesAndShortUrls.graphql';
import AdminPublishContentLimit from 'client/shared/graphql-queries/AdminPublishContentLimit.graphql';
import AdminPublishingEntityContentSetIds from 'client/shared/graphql-queries/AdminPublishingEntityContentSetIds.graphql';
import AdminPublishingEntityContentList from 'client/shared/graphql-queries/AdminPublishingEntityContentList.graphql';
import AdminDashboardDemographics from 'client/shared/graphql-queries/AdminDashboardDemographics.graphql';
import AdminDashboardLocation from 'client/shared/graphql-queries/AdminDashboardLocation.graphql';
import AdminDashboard from 'client/shared/graphql-queries/AdminDashboard.graphql';
import SuperAdminBenchmarkPdfs from 'client/shared/graphql-queries/SuperAdminBenchmarkPdfs.graphql';
import SuperAdminBenchmarkDataDictionary from 'client/shared/graphql-queries/SuperAdminBenchmarkDataDictionary.graphql';
import SuperAdminBenchmarkReportRequest from 'client/shared/graphql-queries/SuperAdminBenchmarkReportRequest.graphql';
import SuperAdminContentSetSettings from 'client/shared/graphql-queries/SuperAdminContentSetSettings.graphql';
import SuperAdminPlacesList from 'client/shared/graphql-queries/SuperAdminPlacesList.graphql';
import SuperAdminAllBenchmarkReportRequests from 'client/shared/graphql-queries/SuperAdminAllBenchmarkReportRequests.graphql';
import SuperAdminAllSuperAdmins from 'client/shared/graphql-queries/SuperAdminAllSuperAdmins.graphql';
import AdminPublishersWithSeats from 'client/shared/graphql-queries/AdminPublishersWithSeats.graphql';
import AdminPublishersFeatureSettings from 'client/shared/graphql-queries/AdminPublishersFeatureSettings.graphql';
import SuperAdminAllPublishers from 'client/shared/graphql-queries/SuperAdminAllPublishers.graphql';
import SuperAdminPurchases from 'client/shared/graphql-queries/SuperAdminPurchases.graphql';
import SuperAdminFilteredPubs from 'client/shared/graphql-queries/SuperAdminFilteredPubs.graphql';
import SuperAdminRegistrationRequests from 'client/shared/graphql-queries/SuperAdminRegistrationRequests.graphql';
import CurrentAdminManagedPublishingEntities from 'client/shared/graphql-queries/CurrentAdminManagedPublishingEntities.graphql';
import CurrentRespondent from 'client/shared/graphql-queries/CurrentRespondent.graphql';
import CurrentAdminRequestedFeatures from 'client/shared/graphql-queries/CurrentAdminRequestedFeatures.graphql';
import AdminPublishingEntitySimulations from 'client/shared/graphql-queries/AdminPublishingEntitySimulations.graphql';
import AdminPublishingEntitySimulationsWithCategories from 'client/shared/graphql-queries/AdminPublishingEntitySimulationsWithCategories.graphql';
import AdminPublishingEntitySimulationWithResults from 'client/shared/graphql-queries/AdminPublishingEntitySimulationWithResults.graphql';
import AdminPublishingEntityStrategicPlans from 'client/shared/graphql-queries/AdminPublishingEntityStrategicPlans.graphql';
import OpenTrackVariables from 'client/shared/graphql-queries/OpenTrackVariables.graphql';
import AdminOpenDashboardById from 'client/shared/graphql-queries/AdminOpenDashboardById.graphql';
import * as Gql from 'client/shared/graphql-client/graphql-operations.g';

import { queryInfo } from 'client/shared/containers/query';
import CurrentUser from 'client/shared/graphql-queries/CurrentUser.graphql';
import CurrentUserNearbyLocations from 'client/shared/graphql-queries/CurrentUserNearbyLocations.graphql';
import CurrentAdmin from 'client/shared/graphql-queries/CurrentAdmin.graphql';
import AdminRegistrationRoles from 'client/shared/graphql-queries/AdminRegistrationRoles.graphql';
import CurrentAdminRegistrationInfo from 'client/shared/graphql-queries/CurrentAdminRegistrationInfo.graphql';

export const currentAdmin = queryInfo<Gql.CurrentAdmin>(CurrentAdmin);

export const adminRegistrationRoles = queryInfo<Gql.AdminRegistrationRoles>(
  AdminRegistrationRoles
);

export const currentAdminRegistrationInfo =
  queryInfo<Gql.CurrentAdminRegistrationInfo>(CurrentAdminRegistrationInfo);

export const currentAdminRequestedFeatures =
  queryInfo<Gql.CurrentAdminRequestedFeatures>(CurrentAdminRequestedFeatures);

export const currentRespondent = queryInfo<Gql.CurrentRespondent>(CurrentRespondent);

export const currentUser = queryInfo<Gql.CurrentUser>(CurrentUser);

export const currentUserNearbyLocations = queryInfo<Gql.CurrentUserNearbyLocations>(
  CurrentUserNearbyLocations
);

export const currentAdminManagedPublishingEntities = queryInfo<
  Gql.CurrentAdminManagedPublishingEntities,
  Gql.CurrentAdminManagedPublishingEntitiesVariables
>(CurrentAdminManagedPublishingEntities);

export const superAdminRegistrationRequests =
  queryInfo<Gql.SuperAdminRegistrationRequests>(SuperAdminRegistrationRequests);

export const superAdminFilteredPubs = queryInfo<
  Gql.SuperAdminFilteredPubs,
  Gql.SuperAdminFilteredPubsVariables
>(SuperAdminFilteredPubs);

export const superAdminPurchases =
  queryInfo<Gql.SuperAdminPurchases>(SuperAdminPurchases);

export const superAdminAllPublishers = queryInfo<
  Gql.SuperAdminAllPublishers,
  Gql.SuperAdminAllPublishersVariables
>(SuperAdminAllPublishers);
export const adminPublishersFeatureSettings = queryInfo<
  Gql.AdminPublishersFeatureSettings,
  Gql.AdminPublishersFeatureSettingsVariables
>(AdminPublishersFeatureSettings);

export const adminPublishersWithSeats = queryInfo<
  Gql.AdminPublishersWithSeats,
  Gql.AdminPublishersWithSeatsVariables
>(AdminPublishersWithSeats);

export const superAdminAllSuperAdmins = queryInfo<Gql.SuperAdminAllSuperAdmins>(
  SuperAdminAllSuperAdmins
);

export const superAdminPlacesList = queryInfo<
  Gql.SuperAdminPlacesList,
  Gql.SuperAdminPlacesListVariables
>(SuperAdminPlacesList);

export const superAdminContentSetSettings = queryInfo<
  Gql.SuperAdminContentSetSettings,
  Gql.SuperAdminContentSetSettingsVariables
>(SuperAdminContentSetSettings);

export const superAdminAllBenchmarkReportRequests =
  queryInfo<Gql.SuperAdminAllBenchmarkReportRequests>(
    SuperAdminAllBenchmarkReportRequests
  );

export const superAdminBenchmarkReportRequest = queryInfo<
  Gql.SuperAdminBenchmarkReportRequest,
  Gql.SuperAdminBenchmarkReportRequestVariables
>(SuperAdminBenchmarkReportRequest);

export const superAdminBenchmarkDataDictionary = queryInfo<
  Gql.SuperAdminBenchmarkDataDictionary,
  Gql.SuperAdminBenchmarkDataDictionaryVariables
>(SuperAdminBenchmarkDataDictionary);

export const superAdminBenchmarkPdfs = queryInfo<
  Gql.SuperAdminBenchmarkPdfs,
  Gql.SuperAdminBenchmarkPdfsVariables
>(SuperAdminBenchmarkPdfs);

export const adminDashboard = queryInfo<
  Gql.AdminDashboard,
  Gql.AdminDashboardVariables
>(AdminDashboard, {
  neverBatch: true,
});

export const adminDashboardLocation = queryInfo<
  Gql.AdminDashboardLocation,
  Gql.AdminDashboardLocationVariables
>(AdminDashboardLocation, {
  neverBatch: true,
});

export const adminDashboardDemographics = queryInfo<
  Gql.AdminDashboardDemographics,
  Gql.AdminDashboardDemographicsVariables
>(AdminDashboardDemographics, {
  neverBatch: true,
});

export const adminPublishingEntityContentList = queryInfo<
  Gql.AdminPublishingEntityContentList,
  Gql.AdminPublishingEntityContentListVariables
>(AdminPublishingEntityContentList);

export const adminPublishingEntityContentSetIds = queryInfo<
  Gql.AdminPublishingEntityContentSetIds,
  Gql.AdminPublishingEntityContentSetIdsVariables
>(AdminPublishingEntityContentSetIds);

export const adminPublishContentLimit = queryInfo<
  Gql.AdminPublishContentLimit,
  Gql.AdminPublishContentLimitVariables
>(AdminPublishContentLimit);

export const adminPublishingEntityContentSetNamesAndShortUrls = queryInfo<
  Gql.AdminPublishingEntityContentSetNamesAndShortUrls,
  Gql.AdminPublishingEntityContentSetNamesAndShortUrlsVariables
>(AdminPublishingEntityContentSetNamesAndShortUrls);

export const adminStore = queryInfo<Gql.AdminStore, Gql.AdminStoreVariables>(
  AdminStore
);

export const adminStoreItem = queryInfo<
  Gql.AdminStoreItem,
  Gql.AdminStoreItemVariables
>(AdminStoreItem);

export const adminQuestionStats = queryInfo<
  Gql.AdminQuestionStats,
  Gql.AdminQuestionStatsVariables
>(AdminQuestionStats);

export const adminQuestionResultsHeader = queryInfo<
  Gql.AdminQuestionResultsHeader,
  Gql.AdminQuestionResultsHeaderVariables
>(AdminQuestionResultsHeader);

export const adminQuestionResultsAdvanced = queryInfo<
  Gql.AdminQuestionResultsAdvanced,
  Gql.AdminQuestionResultsAdvancedVariables
>(AdminQuestionResultsAdvanced);

export const adminQuestionResultsAdvancedMap = queryInfo<
  Gql.AdminQuestionResultsAdvancedMap,
  Gql.AdminQuestionResultsAdvancedMapVariables
>(AdminQuestionResultsAdvancedMap);

export const adminBenchmarkReportCrosstab = queryInfo<
  Gql.AdminBenchmarkReportCrosstab,
  Gql.AdminBenchmarkReportCrosstabVariables
>(AdminBenchmarkReportCrosstab);

export const adminBenchmarkReportTopicDisparities = queryInfo<
  Gql.AdminBenchmarkReportTopicDisparities,
  Gql.AdminBenchmarkReportTopicDisparitiesVariables
>(AdminBenchmarkReportTopicDisparities);

export const adminBenchmarkReportTopicRankings = queryInfo<
  Gql.AdminBenchmarkReportTopicRankings,
  Gql.AdminBenchmarkReportTopicRankingsVariables
>(AdminBenchmarkReportTopicRankings);

export const adminPublishingEntityVisualizations = queryInfo<
  Gql.AdminPublishingEntityVisualizations,
  Gql.AdminPublishingEntityVisualizationsVariables
>(AdminPublishingEntityVisualizations);

export const votingPage = queryInfo<Gql.Voting, Gql.VotingVariables>(VotingPage);

export const publishingWizard = {
  schedule: queryInfo<
    Gql.AdminPublishingWizardSchedule,
    Gql.AdminPublishingWizardScheduleVariables
  >(AdminPublishingWizardSchedule),
  share: queryInfo<
    Gql.AdminPublishingWizardShare,
    Gql.AdminPublishingWizardShareVariables
  >(AdminPublishingWizardShare),
};

export const openContentSet = queryInfo<
  Gql.AdminOpenQuestionSet,
  Gql.AdminOpenQuestionSetVariables
>(OpenContentSet);

export const adminOpenQuestionSetAggregateResults = queryInfo<
  Gql.AdminOpenQuestionSetAggregateResults,
  Gql.AdminOpenQuestionSetAggregateResultsVariables
>(AdminOpenQuestionSetAggregateResults);

export const adminOpenFullTrendsQuestionSetAggregateResults = queryInfo<
  Gql.AdminOpenFullTrendsQuestionSetAggregateResults,
  Gql.AdminOpenFullTrendsQuestionSetAggregateResultsVariables
>(AdminOpenFullTrendsQuestionSetAggregateResults);

export const adminOpenQuestionSetGridAggregateResults = queryInfo<
  Gql.AdminOpenQuestionSetGridAggregateResults,
  Gql.AdminOpenQuestionSetGridAggregateResultsVariables
>(AdminOpenQuestionSetGridAggregateResults);

export const adminOpenVisualization = queryInfo<
  Gql.AdminOpenVisualization,
  Gql.AdminOpenVisualizationVariables
>(AdminOpenVisualization);

export const adminOpenQuestionSetStructure = queryInfo<
  Gql.AdminOpenQuestionSetStructure,
  Gql.AdminOpenQuestionSetStructureVariables
>(AdminOpenQuestionSetStructure);

export const adminOpenQuestionSetTopicsQualityImportance = queryInfo<
  Gql.AdminOpenQuestionSetTopicsQualityImportance,
  Gql.AdminOpenQuestionSetTopicsQualityImportanceVariables
>(AdminOpenQuestionSetTopicsQualityImportance);

export const adminOpenQuestionSetTopicDetailsQualityImportance = queryInfo<
  Gql.AdminOpenQuestionSetTopicDetailsQualityImportance,
  Gql.AdminOpenQuestionSetTopicDetailsQualityImportanceVariables
>(AdminOpenQuestionSetTopicDetailsQualityImportance);

export const adminOpenQuestionSetTopicsQualityAggregateResult = queryInfo<
  Gql.AdminOpenQuestionSetTopicsQualityAggregateResult,
  Gql.AdminOpenQuestionSetTopicsQualityAggregateResultVariables
>(AdminOpenQuestionSetTopicsQualityAggregateResult);

export const adminOpenQuestionSetTopicDetailsQualityAggregateResult = queryInfo<
  Gql.AdminOpenQuestionSetTopicDetailsQualityAggregateResult,
  Gql.AdminOpenQuestionSetTopicDetailsQualityAggregateResultVariables
>(AdminOpenQuestionSetTopicDetailsQualityAggregateResult);

export const adminOpenQuestionSetCrosstabOptions = queryInfo<
  Gql.AdminOpenQuestionSetCrosstabOptions,
  Gql.AdminOpenQuestionSetCrosstabOptionsVariables
>(AdminOpenQuestionSetCrosstabOptions);

export const adminOverviewParticipants = queryInfo<
  Gql.AdminOverviewParticipants,
  Gql.AdminOverviewParticipantsVariables
>(AdminOverviewParticipants);

export const adminOpenQuestionSetBenchmarkOverviewStructure = queryInfo<
  Gql.AdminOpenQuestionSetBenchmarkOverviewStructure,
  Gql.AdminOpenQuestionSetBenchmarkOverviewStructureVariables
>(AdminOpenQuestionSetBenchmarkOverviewStructure);

export const adminPublishingEntityBenchmarkSurveys = queryInfo<
  Gql.AdminPublishingEntityBenchmarkSurveys,
  Gql.AdminPublishingEntityBenchmarkSurveysVariables
>(AdminPublishingEntityBenchmarkSurveys);

export const adminOpenPublishingEntityGeoViewById = queryInfo<
  Gql.AdminOpenPublishingEntityGeoViewById,
  Gql.AdminOpenPublishingEntityGeoViewByIdVariables
>(AdminOpenPublishingEntityGeoViewById);

export const adminLiveQuestionSetStructure = queryInfo<
  Gql.AdminLiveQuestionSetStructure,
  Gql.AdminLiveQuestionSetStructureVariables
>(AdminLiveQuestionSetStructure);

export const adminLiveQuestionResults = queryInfo<
  Gql.AdminLiveQuestionResults,
  Gql.AdminLiveQuestionResultsVariables
>(AdminLiveQuestionResults);

export const adminOpenQuestionBase = queryInfo<
  Gql.AdminOpenQuestionBase,
  Gql.AdminOpenQuestionBaseVariables
>(AdminOpenQuestionBase);

export const adminOpenQuestionSetBase = queryInfo<
  Gql.AdminOpenQuestionSetBase,
  Gql.AdminOpenQuestionSetBaseVariables
>(AdminOpenQuestionSetBase);

export const adminOpenQuestion = queryInfo<
  Gql.AdminOpenQuestion,
  Gql.AdminOpenQuestionVariables
>(AdminOpenQuestion);

export const adminOpenQuestionAggregateResults = queryInfo<
  Gql.AdminOpenQuestionAggregateResults,
  Gql.AdminOpenQuestionAggregateResultsVariables
>(AdminOpenQuestionAggregateResults);

export const adminOpenQuestionStructure = queryInfo<
  Gql.AdminOpenQuestionStructure,
  Gql.AdminOpenQuestionStructureVariables
>(AdminOpenQuestionStructure);

export const openQuestionWithComments = queryInfo<
  Gql.OpenQuestionWithComments,
  Gql.OpenQuestionWithCommentsVariables
>(OpenQuestionWithComments);

export const publishingEntityById = queryInfo<
  Gql.PublishingEntityById,
  Gql.PublishingEntityByIdVariables
>(PublishingEntityById);

export const publishingEntityCreationForm = queryInfo<
  Gql.PublishingEntityCreationForm,
  Gql.PublishingEntityCreationFormVariables
>(PublishingEntityCreationForm);

export const adminPublishingEntityOutreach = queryInfo<
  Gql.AdminPublishingEntityOutreach,
  Gql.AdminPublishingEntityOutreachVariables
>(AdminPublishingEntityOutreach);

export const adminPublishingEntitySettings = queryInfo<
  Gql.AdminPublishingEntitySettings,
  Gql.AdminPublishingEntitySettingsVariables
>(AdminPublishingEntitySettings);

export const adminPublishingEntityFeatureSettings = queryInfo<
  Gql.AdminPublishingEntityFeatureSettings,
  Gql.AdminPublishingEntityFeatureSettingsVariables
>(AdminPublishingEntityFeatureSettings);

export const adminPublishingEntityRoles = queryInfo<
  Gql.AdminPublishingEntityRoles,
  Gql.AdminPublishingEntityRolesVariables
>(AdminPublishingEntityRoles);

export const adminPublishingEntityIdeaSubmissions = queryInfo<
  Gql.AdminPublishingEntityIdeaSubmissions,
  Gql.AdminPublishingEntityIdeaSubmissionsVariables
>(AdminPublishingEntityIdeaSubmissions);

export const adminPublishingEntityComparisonGroups = queryInfo<
  Gql.AdminPublishingEntityComparisonGroups,
  Gql.AdminPublishingEntityComparisonGroupsVariables
>(AdminPublishingEntityComparisonGroups);

export const adminContentSetRespondentsStats = queryInfo<
  Gql.AdminContentSetRespondentsStats,
  Gql.AdminContentSetRespondentsStatsVariables
>(AdminContentSetRespondentsStats);

export const adminContentSetRespondentStatsWithRepresentivityData = queryInfo<
  Gql.AdminContentSetRespondentStatsWithRepresentivityData,
  Gql.AdminContentSetRespondentStatsWithRepresentivityDataVariables
>(AdminContentSetRespondentStatsWithRepresentivityData);

export const adminContentSetRespondentsStatsLocation = queryInfo<
  Gql.AdminContentSetRespondentsStatsLocation,
  Gql.AdminContentSetRespondentsStatsLocationVariables
>(AdminContentSetRespondentsStatsLocation);

export const adminLoadQuestionSetCreationData = queryInfo<
  Gql.AdminLoadQuestionSetCreationData,
  Gql.AdminLoadQuestionSetCreationDataVariables
>(AdminLoadQuestionSetCreationData);

export const adminLoadQuestionSetImages = queryInfo<
  Gql.AdminLoadQuestionSetImages,
  Gql.AdminLoadQuestionSetImagesVariables
>(AdminLoadQuestionSetImages);

export const superAdminPublishingEntityConfigurationDashboard = queryInfo<
  Gql.SuperAdminPublishingEntityConfigurationDashboard,
  Gql.SuperAdminPublishingEntityConfigurationDashboardVariables
>(SuperAdminPublishingEntityConfigurationDashboard);

export const adminMenuItems = queryInfo<Gql.AdminMenuItems>(AdminMenuItems);

export const adminNcsFacetSummaries = queryInfo<Gql.AdminNcsFacetSummaries>(
  AdminNcsFacetSummaries
);

export const adminContentReport = queryInfo<
  Gql.AdminContentReport,
  Gql.AdminContentReportVariables
>(AdminContentReport);

export const adminReadyToShareQuestions = queryInfo<
  Gql.AdminReadyToShareQuestions,
  Gql.AdminReadyToShareQuestionsVariables
>(AdminReadyToShareQuestions);

export const respondentFeed = queryInfo<
  Gql.RespondentFeed,
  Gql.RespondentFeedVariables
>(RespondentFeed);

export const adminCopyContentSetData = queryInfo<Gql.AdminCopyContentSetData>(
  AdminCopyContentSetData
);

export const respondentVotingPage = queryInfo<
  Gql.RespondentVotingPage,
  Gql.RespondentVotingPageVariables
>(RespondentVotingPage);

export const respondentOutcomePage = queryInfo<
  Gql.RespondentOutcomePage,
  Gql.RespondentOutcomePageVariables
>(RespondentOutcomePage);

export const respondentOverviewPage = queryInfo<
  Gql.RespondentOverviewPage,
  Gql.RespondentOverviewPageVariables
>(RespondentOverviewPage);

export const respondentPolcoLiveBase = queryInfo<
  Gql.RespondentPolcoLiveBase,
  Gql.RespondentPolcoLiveBaseVariables
>(RespondentPolcoLiveBase);

export const respondentPublisherProfile = queryInfo<
  Gql.RespondentPublisherProfile,
  Gql.RespondentPublisherProfileVariables
>(RespondentPublisherProfile);

export const respondentSubscriptions = queryInfo<
  Gql.RespondentSubscriptions,
  Gql.RespondentSubscriptionsVariables
>(RespondentSubscriptions);

export const RespondentActiveSubscriptionsForIdea = queryInfo<
  Gql.RespondentActiveSubscriptionsForIdea,
  Gql.RespondentActiveSubscriptionsForIdeaVariables
>(respondentActiveSubscriptionsForIdea);

export const respondentProfile = queryInfo<
  Gql.RespondentProfile,
  Gql.RespondentProfileVariables
>(RespondentProfile);

export const adminLoadPublisherShareConfigurations = queryInfo<
  Gql.AdminLoadPublisherShareConfigurations,
  Gql.AdminLoadPublisherShareConfigurationsVariables
>(AdminLoadPublisherShareConfigurations);

export const adminLoadEmailListWithEmails = queryInfo<
  Gql.AdminLoadEmailListWithEmails,
  Gql.AdminLoadEmailListWithEmailsVariables
>(AdminLoadEmailListWithEmails);

export const superAdminExportTranslations = queryInfo<
  Gql.SuperAdminExportTranslations,
  Gql.SuperAdminExportTranslationsVariables
>(SuperAdminExportTranslations);

export const adminLibraryContentSearch = queryInfo<
  Gql.AdminLibraryContentSearch,
  Gql.AdminLibraryContentSearchVariables
>(AdminLibraryContentSearch);

export const adminPublishingEntityPerformanceData = queryInfo<
  Gql.AdminPublishingEntityPerformanceData,
  Gql.AdminPublishingEntityPerformanceDataVariables
>(AdminPublishingEntityPerformanceData);

export const adminPublishingEntityPerformanceDataByDomain = queryInfo<
  Gql.AdminPublishingEntityPerformanceDataByDomain,
  Gql.AdminPublishingEntityPerformanceDataByDomainVariables
>(AdminPublishingEntityPerformanceDataByDomain);

export const adminComparisonGroupFipsShapes = queryInfo<
  Gql.AdminComparisonGroupFipsShapes,
  Gql.AdminComparisonGroupFipsShapesVariables
>(AdminComparisonGroupFipsShapes);

export const adminComparisonGroupTrackOverview = queryInfo<
  Gql.AdminComparisonGroupTrackOverview,
  Gql.AdminComparisonGroupTrackOverviewVariables
>(AdminComparisonGroupTrackOverview);

export const adminComparisonGroupDomainTrackVariables = queryInfo<
  Gql.AdminComparisonGroupDomainTrackVariables,
  Gql.AdminComparisonGroupDomainTrackVariablesVariables
>(AdminComparisonGroupDomainTrackVariables);

export const adminComparisonGroupDomainIndexScores = queryInfo<
  Gql.AdminComparisonGroupDomainIndexScores,
  Gql.AdminComparisonGroupDomainIndexScoresVariables
>(AdminComparisonGroupDomainIndexScores);

export const adminComparisonGroupDomainIndicators = queryInfo<
  Gql.AdminComparisonGroupDomainIndicators,
  Gql.AdminComparisonGroupDomainIndicatorsVariables
>(AdminComparisonGroupDomainIndicators, { neverBatch: true });

export const adminPublishingEntityQualityImportanceMatrix = queryInfo<
  Gql.AdminPublishingEntityQualityImportanceMatrix,
  Gql.AdminPublishingEntityQualityImportanceMatrixVariables
>(AdminPublishingEntityQualityImportanceMatrix);

export const adminTrackUpsellPerformanceDataVariable = queryInfo<
  Gql.AdminTrackUpsellPerformanceDataVariable,
  Gql.AdminTrackUpsellPerformanceDataVariableVariables
>(AdminTrackUpsellPerformanceDataVariable);

export const adminOpenBalancingActSimulation = queryInfo<
  Gql.AdminOpenBalancingActSimulation,
  Gql.AdminOpenBalancingActSimulationVariables
>(AdminOpenBalancingActSimulation);

export const adminOpenBalancingActTaxReceipt = queryInfo<
  Gql.AdminOpenBalancingActTaxReceipt,
  Gql.AdminOpenBalancingActTaxReceiptVariables
>(AdminOpenBalancingActTaxReceipt);

export const adminPublishingEntitySimulations = queryInfo<
  Gql.AdminPublishingEntitySimulations,
  Gql.AdminPublishingEntitySimulationsVariables
>(AdminPublishingEntitySimulations);

export const adminPublishingEntitySimulationWithResults = queryInfo<
  Gql.AdminPublishingEntitySimulationWithResults,
  Gql.AdminPublishingEntitySimulationWithResultsVariables
>(AdminPublishingEntitySimulationWithResults);

export const adminPublishingEntitySimulationWithCategories = queryInfo<
  Gql.AdminPublishingEntitySimulationsWithCategories,
  Gql.AdminPublishingEntitySimulationsWithCategoriesVariables
>(AdminPublishingEntitySimulationsWithCategories);

export const adminPublishingEntityStrategicPlans = queryInfo<
  Gql.AdminPublishingEntityStrategicPlans,
  Gql.AdminPublishingEntityStrategicPlansVariables
>(AdminPublishingEntityStrategicPlans);

export const openIdea = queryInfo<Gql.OpenIdea, Gql.OpenIdeaVariables>(OpenIdea);

export const openTrackVariables = queryInfo<
  Gql.OpenTrackVariables,
  Gql.OpenTrackVariablesVariables
>(OpenTrackVariables);

export const currentActiveFlags = queryInfo<
  Gql.CurrentActiveFlags,
  Gql.CurrentActiveFlagsVariables
>(CurrentActiveFlags);

export const currentAiAssistantQueryStatus = queryInfo<
  Gql.AdminCurrentAiAssistantQueryStatus,
  Gql.AdminCurrentAiAssistantQueryStatusVariables
>(CurrentAiAssistantQueryStatus);
export const currentAiAssistantSessionStatus = queryInfo<
  Gql.AdminCurrentAiAssistantSessionStatus,
  Gql.AdminCurrentAiAssistantSessionStatusVariables
>(CurrentAiAssistantSessionStatus);
export const currentAiAssistantSessionConversation = queryInfo<
  Gql.AdminCurrentAiAssistantSessionConversation,
  Gql.AdminCurrentAiAssistantSessionConversationVariables
>(CurrentAiAssistantSessionConversation);
export const adminPublishingEntityAiAssistantInteractions = queryInfo<
  Gql.AdminPublishingEntityAiAssistantInteractions,
  Gql.AdminPublishingEntityAiAssistantInteractionsVariables
>(AdminPublishingEntityAiAssistantInteractions);
export const adminOpenDashboardById = queryInfo<
  Gql.AdminOpenDashboardById,
  Gql.AdminOpenDashboardByIdVariables
>(AdminOpenDashboardById);
