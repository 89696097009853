import { DatumValue, Point, PointTooltipProps } from '@nivo/line';
import React from 'react';
import { formatValueByStatisticType, formatXAxisValue } from '.';
import { DomainIndicatorsFipsAreaRow } from '../domain-indicators-group-wrapper';
import { TrackVariable } from 'core';
import './styles.scss';
import { PredictiveModelType } from '@polco-us/types';

const baseClass = 'pn-domain-indicators-group-line-chart-tooltip';

export interface ExpandedPoint extends Omit<Point, 'data'> {
  readonly data: {
    readonly x: DatumValue;
    readonly xFormatted: string | number;
    readonly y: DatumValue;
    readonly yFormatted: string | number;
    readonly [key: string]: any;
    readonly yStacked?: number;
  };
}
interface Props {
  readonly id: any;
  readonly tooltipProps: Omit<PointTooltipProps, 'point'> & {
    readonly point: ExpandedPoint;
  };
  readonly dataByDataAreaFips: _.Dictionary<DomainIndicatorsFipsAreaRow>;
  readonly variable: Pick<
    TrackVariable,
    'valueType' | 'dateLevel' | 'statisticType'
  >;
  readonly goalLine?: number;
}

export const TrackLineChartTooltip: React.FC<Props> = ({
  id,
  tooltipProps,
  dataByDataAreaFips,
  variable,
  goalLine,
}) => {
  const { point } = tooltipProps;

  const dataArea =
    dataByDataAreaFips[point.data.fips].trackAreaData.performanceData[0].fipsArea;
  return (
    <div
      className="d-flex flex-column align-items-center"
      id={id}
      style={{
        background: 'white',
        padding: '9px 12px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        zIndex: 999999,
      }}
    >
      <div className="font-weight-bold font-size-sm mb-2">{dataArea.name}</div>
      <div className="d-flex flex-row align-items-center justify-content-between w-100">
        <div className="d-flex align-items-center">
          <svg
            className={`${baseClass} mr-1 ${point.data.modelType !== PredictiveModelType.REAL && 'mt-2'}`}
          >
            {!point.data.modelType ||
            point.data.modelType === PredictiveModelType.REAL ? (
              <circle cx={8} cy={8} fill={point.color} r={8} />
            ) : (
              <line
                stroke={point.color}
                stroke-dasharray="2"
                stroke-width={2}
                x1={0}
                x2={16}
                y1={5}
                y2={5}
              />
            )}
          </svg>
          <p className="font-size-sm">
            {formatXAxisValue(point.data.x, variable.dateLevel)}{' '}
            {point.data.modelType &&
              point.data.modelType !== PredictiveModelType.REAL && (
                <span className="font-size-xs">(predicted)</span>
              )}
          </p>
        </div>
        <p className="ml-2 font-size-sm font-weight-bold">
          {formatValueByStatisticType({
            value: +point.data.yFormatted.toString(),
            statisticType: variable.statisticType,
            valueType: variable.valueType,
          })}
        </p>
      </div>
      {goalLine && (
        <>
          <hr className="border-top my-1 border-gray-20 w-100 mx-1" />
          <div className="d-flex flex-row align-items-center justify-content-between w-100">
            <div className="d-flex">
              <svg className={`${baseClass}-line mr-1 mt-2`}>
                <line
                  stroke={'#B72E1C'}
                  stroke-dasharray="2"
                  stroke-width={2}
                  x1={0}
                  x2={16}
                  y1={5}
                  y2={5}
                />
              </svg>
              <p className="font-size-sm">Goal</p>
            </div>
            <p className="ml-2 font-size-sm font-weight-bold">
              {formatValueByStatisticType({
                value: +goalLine,
                statisticType: variable.statisticType,
                valueType: variable.valueType,
              })}
            </p>
          </div>
        </>
      )}
      {!!point.data.modelType &&
        point.data.modelType !== PredictiveModelType.REAL && (
          <div>
            {!goalLine && (
              <hr className="border-top my-1 border-gray-20 w-100 mx-1" />
            )}
            <div className="d-flex flex-row align-items-center justify-content-between w-100 align-self-start">
              <div className="d-flex " style={{ maxWidth: '150px' }}>
                <p className="font-size-xs">
                  Prediction calculated using the AR(1) Predictive Model
                </p>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};
