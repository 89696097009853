import * as React from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import './styles.scss';
import { handlePressEnter } from 'client/shared/core/helpers';
import { isEqual } from 'lodash';
import { KeyPressKey } from 'client/shared/core/types';

export enum InlineDisplayOptions {
  CENTER = 'CENTER',
  LEFT = 'LEFT',
}

const baseClass = 'pn-radio-group';

interface Props<T> {
  readonly ariaInvalid?: boolean;
  readonly applyAccessibleStyles?: boolean;
  readonly value?: T;
  readonly label?: string;
  readonly disabled?: boolean;
  readonly name?: string;
  readonly options: ReadonlyArray<T>;
  readonly keySelect: (value: T) => string;
  readonly labelSelect: (value: T) => React.ReactNode;
  readonly descriptionSelect?: (value: T) => React.ReactNode;
  readonly onChange: (value: T) => void;
  readonly additionalNode?: (value: T) => React.ReactNode;
  //if the label is rendered outside of this component, pass an htmlId to connect them
  readonly htmlId?: string;
  readonly displayInline?: InlineDisplayOptions;
  readonly className?: string;
  readonly inputClassName?: string;
  readonly required?: boolean;
  // Pass this when rendering multiple, identical radio groups on the same page.
  // Such as rendering duplicates of a component that itself renders a radio group.
  // Otherwise clicking the radios in any of the groups will only update the first one.
  readonly identifier?: string;
}

export function RadioGroup<T>(
  props: Props<T> & { readonly children?: React.ReactNode }
): React.ReactElement {
  const renderedOptions = props.options.map((opt: T) => {
    const key = props.keySelect(opt);
    const checked = props.value && props.keySelect(props.value) === key;

    return (
      <div
        className={`pb-2 ${baseClass}-item ${props.displayInline ? 'pr-4' : ''} d-flex`}
        id={props.htmlId}
        key={key}
      >
        {process.env.NODE_ENV === 'test' ? (
          <label className={classNames(props.inputClassName)}>
            {props.labelSelect(opt)}
            <input
              checked={isEqual(props.value, opt)}
              disabled={props.disabled}
              key={key}
              onChange={() => handleChange(isEqual(props.value, opt), opt)}
              required={props.required}
              type="radio"
            />
          </label>
        ) : (
          <Form.Check
            aria-invalid={props.ariaInvalid}
            checked={!!checked}
            className={classNames(
              {
                [`${baseClass}-accessible-radio`]: props.applyAccessibleStyles,
                'is-checked': checked,
              },
              props.inputClassName
            )}
            custom
            disabled={props.disabled}
            // Needs to be unique on the page, not just within the group.
            id={`${key}${props.identifier ?? ''}`}
            label={props.labelSelect(opt)}
            name={props.name}
            onChange={() => props.onChange(opt)}
            onClick={() => handleChange(checked, opt)}
            onKeyDown={(e) => {
              if (e.key === KeyPressKey.SPACE) {
                e.preventDefault();
                handleChange(checked, opt);
              }
            }}
            required={props.required}
            type="radio"
          />
        )}
        {props.additionalNode && props.additionalNode(opt)}
        {props.descriptionSelect && (
          <small
            className="d-block pl-4 pt-1 pb-2 text-gray-40"
            onClick={() => props.onChange(opt)}
            onKeyDown={handlePressEnter(() => props.onChange(opt))}
            role="button"
            tabIndex={0}
          >
            {props.descriptionSelect(opt)}
          </small>
        )}
      </div>
    );
  });

  return (
    <div
      className={`${classNames(baseClass, {
        'is-disabled': props.disabled,
        'mod-with-description': props.descriptionSelect,
        'd-flex justify-content-center pt-1':
          props.displayInline === InlineDisplayOptions.CENTER,
        'd-flex pt-1': props.displayInline === InlineDisplayOptions.LEFT,
      })} ${props.className ?? ''}`}
    >
      {props.label && (
        <label className="d-block" htmlFor={props.label}>
          {props.label}
        </label>
      )}
      {renderedOptions}
    </div>
  );

  function handleChange(checked: boolean | undefined, opt: T) {
    if (!props.required && checked) {
      props.onChange({} as T);
    } else {
      props.onChange(opt);
    }
  }
}
