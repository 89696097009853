import * as Gql from 'client/shared/graphql-client/graphql-operations.g';
import * as QuestionSharedCore from 'client/shared/core/question';
import * as QuestionCore from '../../core/question';
import _ from 'lodash';
import {
  ApiDate,
  DEFAULT_SURVEY_QUESTION_OPTIONALITY,
  ExtractGql,
  maybeMap,
  prune,
  SimulationType,
  wrap,
} from 'core';
import {
  ExampleQuestion,
  ExampleQuestionResults,
} from 'client/admin/example-questions/core';
import { ScheduleTx } from './schedule';
import { ConditionTx } from './condition';
import { ClientMetaconditionEphemeralReferenceId } from 'client/admin/core/conditions';
import {
  GqlConditions,
  gqlToClient_conditions,
} from 'client/shared/core/conditions';
import {
  MultiLanguageContent,
  SelectLanguageTextFunction,
} from 'client/shared/hooks';
import { ClientPublishingEntityId } from 'client/shared/core/publishing-entity';
import { BenchmarkAggregateTx } from './benchmark-aggregate';
import { errorLogger } from 'client/shared/core/error-handler';

export type GqlQuestion = ExtractGql<
  NonNullable<Gql.AdminPublishingWizardSchedule['openContentSetById']>,
  'QuestionSet'
>['cartQuestions']['questions'][0];
export type QuestionSetQuestion = NonNullable<
  | Gql.SetPollSetQuestion['setPollSetQuestion']
  | Gql.AdminOpenQuestionStructure['openQuestion']
>;

type AdminOpenQuestionSetContents = Gql.AdminOpenQuestionSet_Survey['contents'][0];
export type GqlHierarchyParentNode = ExtractGql<
  AdminOpenQuestionSetContents,
  'QuestionHierarchyParentNode'
>;
export type SurveyQuestion = ExtractGql<AdminOpenQuestionSetContents, 'Question'>;
export type GridQuestion = GqlHierarchyParentNode;
export type SurveyHeader = GqlHierarchyParentNode;
export type SetVisualization = ExtractGql<
  AdminOpenQuestionSetContents,
  'QuestionHierarchyVisualizationNode'
>;
export type SetSimulation = ExtractGql<
  AdminOpenQuestionSetContents,
  'QuestionHierarchySimulationNode'
>;
export type GridQuestionOrHeader = GridQuestion | SurveyHeader;
export type SurveyQuestionOrGrid = SurveyQuestion | GridQuestion;
type AdminOpenQuestionSetQuestionSetCreatedQuestions =
  Gql.AdminOpenQuestionSet_QuestionSet['createdQuestions'];
export type SetQuestion =
  AdminOpenQuestionSetQuestionSetCreatedQuestions['questions'][0];
export type SetQuestionResults = SetQuestion['results'][0];
type AggregateResultsSurvey = ExtractGql<
  NonNullable<Gql.AdminOpenQuestionSetAggregateResults['openContentSetById']>,
  'Survey'
>;
export type SurveyContentsWithAggregateResults =
  AggregateResultsSurvey['contents'][0];
type AggregateResultsQuestion = NonNullable<
  Gql.AdminOpenQuestionAggregateResults['openQuestion']
>;
export type SurveyContentsPublisher =
  | AggregateResultsSurvey['publishingEntity']
  | AggregateResultsQuestion['questionSet']['publishingEntity'];
export type QuestionWithAggregateResults =
  | ExtractGql<SurveyContentsWithAggregateResults, 'Question'>
  | AggregateResultsQuestion;
export type AggregateResults = NonNullable<
  | ExtractGql<SurveyContentsWithAggregateResults, 'Question'>['aggregateResults']
  | ExtractGql<
      SurveyContentsWithAggregateResults,
      'QuestionHierarchyParentNode'
    >['questions'][0]['aggregateResults']
>;
export type LiveQuestion =
  | Gql.AdminOpenQuestionSet_PolcoLive['createdQuestions']['questions'][0]
  | NonNullable<Gql.AdminLiveQuestionResults['openQuestion']>;

export type SetOrSurveyContent =
  | SetVisualization
  | SetSimulation
  | SetQuestion
  | LiveQuestion
  | SurveyQuestionOrGrid
  | SurveyHeader;
export type ShareableSurveyQuestion = ExtractGql<
  ExtractGql<
    NonNullable<
      Gql.AdminReadyToShareQuestions['adminShareableContentSet']
    >['contentSet'],
    'Survey'
  >['contents'][0],
  'Question'
>;

type GqlChoiceSet = SurveyQuestion['choiceSet'];
export type GqlVotingChoice =
  | SetQuestion['results'][0]
  | SurveyQuestion['results'][0]
  | NonNullable<Gql.AdminLiveQuestionResults['openQuestion']>['results'][0]
  | ExampleQuestionResults;

type GqlScaleData = NonNullable<
  Gql.AdminLiveQuestionResults['openQuestion']
>['choiceSet']['scaleData'];
export namespace QuestionTx {
  // NOTE: This is semi-wrong... Grid's are sort of questions, this fails for that?
  export function isSurveyHeader(q: SetOrSurveyContent): q is SurveyHeader {
    return (
      (q as SurveyQuestionOrGrid).__typename === 'QuestionHierarchyParentNode' &&
      (q as GridQuestionOrHeader).structureType === 'HEADER'
    );
  }

  export function isSurveyGrid(q: SetOrSurveyContent): q is GridQuestion {
    return (
      ((q as SurveyQuestionOrGrid).__typename === 'QuestionHierarchyParentNode' &&
        (q as GridQuestionOrHeader).structureType === 'GRID') ||
      isSurveyQuestion(q)
    );
  }

  export function isSurveyQuestionOrGrid(
    q: SetOrSurveyContent
  ): q is SurveyQuestionOrGrid {
    return (
      ((q as SurveyQuestionOrGrid).__typename === 'QuestionHierarchyParentNode' &&
        (q as GridQuestionOrHeader).structureType === 'GRID') ||
      isSurveyQuestion(q)
    );
  }

  export function isSurveyQuestion(q: SetOrSurveyContent): q is SurveyQuestion {
    return (
      (q as SurveyQuestion).__typename === 'Question' &&
      (q as SurveyQuestion).questionSet?.__typename === 'Survey'
    );
  }

  interface GqlQuestionIdPart {
    readonly id: string;
  }

  interface GqlQuestionVariableNamePart extends GqlQuestionIdPart {
    readonly variableName?: string | null;
  }

  interface GqlQuestionChoicesPart extends GqlQuestionIdPart {
    readonly choiceSet: {
      readonly type: Gql.QuestionType;
      readonly maxSelection: number;
      readonly randomizeChoices?: boolean | null;
      readonly sharedChoiceSetData: {
        readonly id: string;
      } | null;
      readonly scaleData?: GqlScaleData | null;
      readonly choices: readonly {
        readonly id: string;
        readonly text: MultiLanguageContent;
        readonly choiceValue?: number | null;
        readonly includeComment?: boolean;
      }[];
    };
  }

  function extractQuestionVariableName(
    question: GqlQuestionVariableNamePart
  ): string | null | undefined {
    return question.variableName;
  }

  function extractChoices(
    question: GqlQuestionChoicesPart,
    selectLanguageText: SelectLanguageTextFunction
  ): readonly QuestionSharedCore.QuestionChoice[] {
    return question.choiceSet.choices.map((ch) =>
      prune({
        id: ch.id,
        label: selectLanguageText(ch.text),
        choiceValue: ch.choiceValue,
        includeComment: !!ch.includeComment,
      })
    );
  }

  export interface GqlQuestionSchedulePart extends GqlQuestionIdPart {
    readonly schedule: {
      readonly status: Gql.QuestionStatus;
      readonly openDate?: Omit<ApiDate, '__typename'> | null;
      readonly closeDate?: Omit<ApiDate, '__typename'> | null;
    };
  }

  export interface GqlQuestionCommon
    extends GqlQuestionTyped,
      GqlQuestionSchedulePart {
    readonly title: MultiLanguageContent;
    readonly description: MultiLanguageContent | null;
    readonly optional: boolean;
    readonly reportDisplayLabel?: string | null;
  }

  export function gqlToClient(
    question: GqlQuestionCommon,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionSharedCore.SavedQuestion {
    const typedData = gqlToTypedData(question, selectLanguageText);
    const status = gqlStatusToClient(question.schedule.status, question.id);

    return {
      id: question.id,
      title: selectLanguageText(question.title),
      status,
      description: selectLanguageText(question.description) ?? null,
      images: [],
      typedData,
      shortId: null,
      optional: question.optional,
      reportDisplayLabel: question.reportDisplayLabel,
    };
  }

  export interface GqlQuestionTyped
    extends GqlQuestionChoicesPart,
      GqlQuestionVariableNamePart {}

  function gqlToTypedData(
    question: GqlQuestionTyped,
    selectLanguageText: SelectLanguageTextFunction
  ) {
    const type = QuestionTx.gqlTypeToClient(question.choiceSet.type);

    switch (type) {
      case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
        return {
          variableName: extractQuestionVariableName(question),
          type,
          choices: extractChoices(question, selectLanguageText),
          maxSelection: question.choiceSet.maxSelection,
          randomizeChoices: question.choiceSet.randomizeChoices,
          dataDictionary: question.choiceSet.scaleData?.scaleType
            ? {
                scaleType: question.choiceSet.scaleData.scaleType,
                scaleThreshold: question.choiceSet.scaleData.scaleThreshold,
              }
            : null,
          shareableQuestionChoiceSetId:
            question.choiceSet.sharedChoiceSetData?.id ?? null,
        };
      case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
        return {
          type,
          variableName: extractQuestionVariableName(question),
          choices: extractChoices(question, selectLanguageText),
          dataDictionary: question.choiceSet.scaleData?.scaleType
            ? {
                scaleType: question.choiceSet.scaleData.scaleType,
                scaleThreshold: question.choiceSet.scaleData.scaleThreshold,
              }
            : null,
          shareableQuestionChoiceSetId:
            question.choiceSet.sharedChoiceSetData?.id ?? null,
        };
      case QuestionSharedCore.QuestionType.FREE_TEXT:
        return {
          type,
          variableName: extractQuestionVariableName(question),
        };
      case QuestionSharedCore.QuestionType.GRID_CHOICE:
        throw new Error(
          `QuestionTx.gqlToClient for ${QuestionSharedCore.QuestionType.GRID_CHOICE} type not implemented`
        );
    }
  }

  export function gqlToBuildingContext(
    gqlQuestion: QuestionSetQuestion,
    questionIdToMetaconditionEphemeralReferenceId: {
      readonly [questionId: string]: ClientMetaconditionEphemeralReferenceId;
    },
    dataDictionary: QuestionSharedCore.ChoiceSetDataDictionary | null,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.Building_Question {
    const savedQuestion = gqlToClient(gqlQuestion, selectLanguageText);
    const buildingQuestion = savedToBuilding(
      savedQuestion,
      questionIdToMetaconditionEphemeralReferenceId,
      dataDictionary
    );
    return buildingQuestion;
  }

  export function savedToBuilding(
    question:
      | QuestionSharedCore.SavedQuestion
      | QuestionSharedCore.QuestionWithExtendedData,
    questionIdToMetaconditionEphemeralReferenceId: {
      readonly [questionId: string]: ClientMetaconditionEphemeralReferenceId;
    },
    dataDictionary: QuestionSharedCore.ChoiceSetDataDictionary | null
  ): QuestionCore.Building_Question {
    const questionwithDefaultExtendedData = {
      demographicAttribute: null,
      ephemeralReferenceId:
        question.id as QuestionCore.ClientQuestionEphemeralReferenceId,
      ...question,
    };

    switch (question.typedData.type) {
      case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
      case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
        return {
          ...questionwithDefaultExtendedData,
          metaconditionEphemeralReferenceId:
            questionIdToMetaconditionEphemeralReferenceId[question.id],
          typedData: {
            ...question.typedData,
            dataDictionary,
            choices: question.typedData.choices.map((choice) => ({
              ...choice,
              ephemeralReferenceId:
                choice.id as QuestionCore.ClientQuestionChoiceEphemeralReferenceId,
            })),
          },
        };
      case QuestionSharedCore.QuestionType.GRID_CHOICE:
        return {
          ...questionwithDefaultExtendedData,
          metaconditionEphemeralReferenceId:
            questionIdToMetaconditionEphemeralReferenceId[question.id],
          typedData: {
            ...question.typedData,
            dataDictionary,
            rows: question.typedData.rows.map((row) => ({
              ...row,
              ephemeralReferenceId:
                row.questionId as QuestionCore.ClientQuestionEphemeralReferenceId,
              conditions: ConditionTx.coreToBuilding(row.conditions),
              metaconditionEphemeralReferenceId:
                questionIdToMetaconditionEphemeralReferenceId[row.questionId],
            })),
            columns: question.typedData.columns.map((column) => ({
              ...column,
              ephemeralReferenceId:
                column.id as QuestionCore.ClientQuestionChoiceEphemeralReferenceId,
            })),
          },
        };
      case QuestionSharedCore.QuestionType.FREE_TEXT:
        return {
          ...questionwithDefaultExtendedData,
          metaconditionEphemeralReferenceId:
            questionIdToMetaconditionEphemeralReferenceId[question.id],
          typedData: {
            ...question.typedData,
          },
        };
    }
  }

  export function gqlToClientWithStatusData(
    q: SetQuestion | SurveyQuestion | ExampleQuestion | LiveQuestion,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.QuestionWithStatusData {
    const type = QuestionTx.gqlTypeToClient(q.choiceSet.type);

    if (type === QuestionSharedCore.QuestionType.GRID_CHOICE) {
      throw new Error(
        `QuestionTx.gqlToClient for ${QuestionSharedCore.QuestionType.GRID_CHOICE} type not implemented`
      );
    }

    const status = gqlStatusToClient(q.schedule.status, q.id);

    const data: QuestionSharedCore.QuestionTypedData = gqlTypedData(
      type,
      q,
      selectLanguageText
    );

    const commentsCount = q.commentsData.totalCount;
    const votesCount = q.totalVotesCount;
    const commentsWordCount = q.commentsWordCount;

    let statusData = {} as
      | QuestionCore.StatusData
      | QuestionCore.StatusDataWithResults;

    if (type === QuestionSharedCore.QuestionType.MULTIPLE_CHOICE) {
      if (
        status === QuestionSharedCore.QuestionStatus.DRAFT ||
        status === QuestionSharedCore.QuestionStatus.CART ||
        status === QuestionSharedCore.QuestionStatus.SCHEDULED
      ) {
        statusData = {
          status,
        };
      } else {
        const qResults: readonly GqlVotingChoice[] = q.results;
        statusData = {
          status,
          stats: {
            commentsCount,
            responsesCount: votesCount ?? 0,
            viewsCount: 0,
          },
          results: {
            type: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
            choicesAreScaled: !!q.choiceSet.scaleData?.scaleType,
            hasResponses: _.sumBy(q.results || [], (r) => r.votesCount || 0) > 0,
            byChoice: votingChoices_gqlToClient({
              questionType: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
              gqlVotingChoices: qResults,
              selectLanguageText,
            }),
            commentsWordCount: q.commentsWordCount,
            // Set all aggregate calculations to null since we aren't loading aggregates
            averageChoiceValueData: null,
            trend: null,
            benchmark: null,
            aggregateValue: null,
          },
        };
      }
    } else if (type === QuestionSharedCore.QuestionType.FREE_TEXT) {
      if (
        status === QuestionSharedCore.QuestionStatus.DRAFT ||
        status === QuestionSharedCore.QuestionStatus.CART ||
        status === QuestionSharedCore.QuestionStatus.SCHEDULED
      ) {
        statusData = {
          status,
        };
      } else {
        statusData = {
          status,
          stats: {
            commentsCount: 0,
            responsesCount: commentsCount,
            viewsCount: 0,
          },
          results: {
            type: QuestionSharedCore.QuestionType.FREE_TEXT,
            hasResponses: commentsCount > 0,
            responses: null,
            wordCount: commentsWordCount,
          },
        };
      }
    } else if (type === QuestionSharedCore.QuestionType.POINT_ALLOCATION) {
      if (
        status === QuestionSharedCore.QuestionStatus.DRAFT ||
        status === QuestionSharedCore.QuestionStatus.CART ||
        status === QuestionSharedCore.QuestionStatus.SCHEDULED
      ) {
        statusData = {
          status,
        };
      } else {
        const qResults: readonly GqlVotingChoice[] = q.results;
        statusData = {
          status,
          stats: {
            commentsCount: q.commentsData.totalCount,
            responsesCount: q.totalVotesCount ?? 0,
            viewsCount: 0,
          },
          results: {
            type: QuestionSharedCore.QuestionType.POINT_ALLOCATION,
            hasResponses: _.sumBy(q.results || [], (r) => r.votesCount || 0) > 0,
            choicesAreScaled: false, // never scaled for Point Allocation
            byChoice: votingChoices_gqlToClient({
              questionType: QuestionSharedCore.QuestionType.POINT_ALLOCATION,
              gqlVotingChoices: qResults,
              selectLanguageText,
            }),
            commentsWordCount: q.commentsWordCount,
            // Set all aggregate calculations to null since we aren't loading aggregates
            averageChoiceValueData: null,
            trend: null,
            benchmark: null,
            aggregateValue: null,
          },
        };
      }
    }

    return {
      id: q.id,
      title: selectLanguageText(q.title),
      description: selectLanguageText(q.description) ?? null,

      images: [],
      typedData: data,
      statusData,
      setId: q.questionSet.id,
      shortId: null,
      optional:
        q.optional ??
        (isSurveyQuestion(q) ? DEFAULT_SURVEY_QUESTION_OPTIONALITY : true),
    };
  }

  export function gqlToClientWithSchedule(
    q: SetQuestion | LiveQuestion,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.ScheduledQuestion {
    const question = QuestionTx.gqlToClientWithStatusData(q, selectLanguageText);
    const schedule = ScheduleTx.gqlToClient(q);
    return {
      ...question,
      schedule,
    };
  }

  export interface GqlQuestionExtended extends GqlQuestionCommon {
    readonly demographicAttribute: {
      readonly id: string;
      readonly name: string;
      readonly premium: boolean;
      readonly owner: { readonly id: ClientPublishingEntityId } | null;
    } | null;
    readonly conditions?: GqlConditions | null;
  }

  export function gqlToClientWithExtendedData(
    question: GqlQuestionExtended,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionSharedCore.QuestionWithExtendedData {
    const base = gqlToClient(question, selectLanguageText);

    return {
      ...base,
      demographicAttribute: question.demographicAttribute,
      conditions: gqlToClient_conditions(question.conditions ?? null),
    };
  }

  function gqlTypedData(
    type: Exclude<
      QuestionSharedCore.QuestionType,
      QuestionSharedCore.QuestionType.GRID_CHOICE
    >,
    q: SetQuestion | SurveyQuestion | ExampleQuestion | LiveQuestion,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionSharedCore.QuestionTypedData {
    if (type === QuestionSharedCore.QuestionType.MULTIPLE_CHOICE) {
      return {
        type: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
        variableName: q.variableName,
        maxSelection: q.choiceSet.maxSelection,
        randomizeChoices: null,
        choices: q.choiceSet.choices.map((ch) => ({
          id: ch.id,
          label: selectLanguageText(ch.text),
          choiceValue: ch.choiceValue,
        })),
        dataDictionary: q.choiceSet.scaleData,
        shareableQuestionChoiceSetId: q.choiceSet.sharedChoiceSetData?.id ?? null,
      };
    } else if (type === QuestionSharedCore.QuestionType.FREE_TEXT) {
      return {
        type: QuestionSharedCore.QuestionType.FREE_TEXT,
        variableName: q.variableName,
      };
    } else {
      return {
        type: QuestionSharedCore.QuestionType.POINT_ALLOCATION,
        choices: q.choiceSet.choices.map((ch) => ({
          id: ch.id,
          label: selectLanguageText(ch.text),
          choiceValue: ch.choiceValue,
        })),
        dataDictionary: q.choiceSet.scaleData,
        shareableQuestionChoiceSetId: q.choiceSet.sharedChoiceSetData?.id ?? null,
      };
    }
  }

  export function gqlTypeToClient(
    c: Gql.QuestionType
  ): QuestionSharedCore.QuestionType {
    switch (c) {
      case Gql.QuestionType.MULTIPLE_CHOICE:
        return QuestionSharedCore.QuestionType.MULTIPLE_CHOICE;
      case Gql.QuestionType.FREE_TEXT:
        return QuestionSharedCore.QuestionType.FREE_TEXT;
      case Gql.QuestionType.POINT_ALLOCATION:
        return QuestionSharedCore.QuestionType.POINT_ALLOCATION;
    }
  }

  export function gqlStatusToClient(
    s: Gql.QuestionStatus,
    questionId: string
  ): QuestionSharedCore.QuestionStatus {
    switch (s) {
      case Gql.QuestionStatus.DRAFT:
        return QuestionSharedCore.QuestionStatus.DRAFT;
      case Gql.QuestionStatus.SCHEDULED:
        return QuestionSharedCore.QuestionStatus.SCHEDULED;
      case Gql.QuestionStatus.PUBLISHED:
        return QuestionSharedCore.QuestionStatus.PUBLISHED;
      case Gql.QuestionStatus.CART:
        return QuestionSharedCore.QuestionStatus.CART;
      case Gql.QuestionStatus.CLOSED:
        return QuestionSharedCore.QuestionStatus.CLOSED;
      case Gql.QuestionStatus.ARCHIVED:
        return QuestionSharedCore.QuestionStatus.ARCHIVED;
      case Gql.QuestionStatus.HISTORIC_RECORD:
        return QuestionSharedCore.QuestionStatus.HISTORIC_RECORD;
      case Gql.QuestionStatus.SOFT_DELETED:
        errorLogger.log(
          `Soft deleted state in client transform. QID: ${questionId}`
        );
        return QuestionSharedCore.QuestionStatus.SOFT_DELETED;
    }
  }

  export function clientToGql(c: QuestionCore.Building_Question): Gql.QuestionInput {
    const scaleData =
      c.typedData.type === QuestionSharedCore.QuestionType.FREE_TEXT
        ? null
        : !c.typedData.dataDictionary
          ? null
          : {
              scaleThreshold: c.typedData.dataDictionary.scaleThreshold,
              scaleType: c.typedData.dataDictionary.scaleType,
            };

    const isMultipleChoice =
      c.typedData.type === QuestionSharedCore.QuestionType.MULTIPLE_CHOICE;

    return {
      questionId: c.id,
      ephemeralReferenceId: c.ephemeralReferenceId,
      title: c.title,
      dataDictionary: {
        reportDisplayLabel: c.reportDisplayLabel,
        scaleData,
      },
      type: clientTypeToGql(c.typedData.type),
      backgroundInfo: c.description || '',
      imageUrls: c.images.map((i) => i),
      choices:
        isMultipleChoice ||
        c.typedData.type === QuestionSharedCore.QuestionType.POINT_ALLOCATION
          ? c.typedData.choices.map((ch) => ({
              id: ch.id,
              ephemeralReferenceId: ch.ephemeralReferenceId,
              text: ch.label,
              choiceValue: ch.choiceValue,
              includeComment: !!ch.includeComment,
            }))
          : [],
      maxSelection: isMultipleChoice ? c.typedData.maxSelection : 1,
      randomizeChoices: isMultipleChoice ? c.typedData.randomizeChoices : null,
      optional: c.optional,
      demographicAttributeId: c.demographicAttribute?.id,
      metaConditionReferenceId: c.metaconditionEphemeralReferenceId,
      variableName:
        c.typedData.type !== QuestionSharedCore.QuestionType.GRID_CHOICE
          ? c.typedData.variableName
          : null,
      shareableQuestionChoiceSetId:
        c.typedData.type !== QuestionSharedCore.QuestionType.FREE_TEXT
          ? c.typedData.shareableQuestionChoiceSetId
          : null,
    };
  }

  export function clientToSurveyGql(
    c: QuestionCore.Building_ValidatedSurveyItem
  ): Gql.SurveyNodeInput {
    switch (c.type) {
      case QuestionSharedCore.SurveyItemType.HEADER:
        return {
          type: Gql.QuestionHierarchyChildInputType.HEADER,
          HEADER: {
            hierarchyId: c.data.id,
            ephemeralReferenceId: c.data.ephemeralReferenceId,
            label: c.data.title,
            description: c.data.typedData.description,
            metaConditionReferenceId: c.data.metaconditionEphemeralReferenceId,
          },
          QUESTION: null,
          GRID: null,
        };
      case QuestionSharedCore.SurveyItemType.VISUALIZATION:
        return {
          type: Gql.QuestionHierarchyChildInputType.VISUALIZATION,
          HEADER: null,
          VISUALIZATION: {
            hierarchyId: c.data.id,
            ephemeralReferenceId: c.data.ephemeralReferenceId,
            visualizationId: c.data.visualizationData.visualization.id,
            metaConditionReferenceId: c.data.metaconditionEphemeralReferenceId,
            label: c.data.visualizationData.label
              ? c.data.visualizationData.label
              : null,
          },
          QUESTION: null,
          GRID: null,
        };
      case QuestionSharedCore.SurveyItemType.SIMULATION:
        return {
          type: Gql.QuestionHierarchyChildInputType.SIMULATION,
          QUESTION: null,
          GRID: null,
          HEADER: null,
          VISUALIZATION: null,
          SIMULATION: {
            hierarchyId: c.data.id,
            ephemeralReferenceId: c.data.ephemeralReferenceId,
            metaConditionReferenceId: c.data.metaconditionEphemeralReferenceId,
            simulationId: c.data.simulationData.simulation.id,
            simulationType: simulationType_clientToGql(c.data.simulationData.type),
          },
        };
      case QuestionSharedCore.SurveyItemType.QUESTION:
        switch (c.data.typedData.type) {
          case QuestionSharedCore.QuestionType.GRID_CHOICE:
            const randomizeChoices = c.data.typedData.randomizeChoices;
            const scaleData = !c.data.typedData.dataDictionary
              ? null
              : {
                  scaleThreshold: c.data.typedData.dataDictionary.scaleThreshold,
                  scaleType: c.data.typedData.dataDictionary.scaleType,
                };

            return {
              type: Gql.QuestionHierarchyChildInputType.GRID,
              HEADER: null,
              QUESTION: null,
              GRID: {
                gridRootHierarchyId: c.data.id,
                ephemeralReferenceId: c.data.ephemeralReferenceId,
                title: c.data.title,
                metaConditionReferenceId: c.data.metaconditionEphemeralReferenceId,
                dataDictionary: {
                  reportDisplayLabel: c.data.reportDisplayLabel,
                  scaleData,
                },
                randomizeChoices: randomizeChoices,
                rows: c.data.typedData.rows.map((r) => ({
                  id: r.questionId,
                  ephemeralReferenceId: r.ephemeralReferenceId,
                  label: r.label,
                  optional: c.data.optional, // apply the grids "optionality" to each row
                  metaConditionReferenceId: r.metaconditionEphemeralReferenceId,
                  reportDisplayLabel: r.reportDisplayLabel,
                  variableName: r.variableName,
                })),
                choices: c.data.typedData.columns.map((ch) => ({
                  id: ch.id,
                  ephemeralReferenceId: ch.ephemeralReferenceId,
                  text: ch.label,
                  choiceValue: ch.choiceValue,
                  includeComment: !!ch.includeComment,
                })),
                shareableQuestionChoiceSetId:
                  c.data.typedData.shareableQuestionChoiceSetId,
              },
            };
          case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
          case QuestionSharedCore.QuestionType.FREE_TEXT:
          case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
            return {
              type: Gql.QuestionHierarchyChildInputType.QUESTION,
              HEADER: null,
              QUESTION: clientToGql(c.data),
              GRID: null,
            };
        }
    }
  }

  export function clientTypeToGql(
    c: QuestionSharedCore.QuestionType
  ): Gql.QuestionType {
    if (c === QuestionSharedCore.QuestionType.GRID_CHOICE) {
      throw new Error(
        `QuestionTx.clientTypeToGql for ${QuestionSharedCore.QuestionType.GRID_CHOICE} type not implemented`
      );
    }

    switch (c) {
      case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
        return Gql.QuestionType.MULTIPLE_CHOICE;
      case QuestionSharedCore.QuestionType.FREE_TEXT:
        return Gql.QuestionType.FREE_TEXT;
      case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
        return Gql.QuestionType.POINT_ALLOCATION;
    }
  }

  export function gqlToClientWithStatusData_Survey(
    question: SurveyQuestionOrGrid,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.QuestionWithStatusData | null {
    if (isSurveyQuestion(question)) {
      return gqlToClientWithStatusData(question, selectLanguageText);
    } else if (isSurveyGrid(question)) {
      const questions = question.questions || [];
      return {
        id: question.id,
        setId: question.survey.id,
        title: selectLanguageText(question.label) ?? 'GRID',
        description: selectLanguageText(question.structureDescription) ?? null,
        images: [],
        statusData: {
          status: QuestionSharedCore.QuestionStatus.PUBLISHED,
          results: {
            type: QuestionSharedCore.QuestionType.GRID_CHOICE,
            results: questions.map((q) => ({
              rowId: q.id,
              rowName: selectLanguageText(q.title),
              rowVariableName: q.variableName,
              totalResponses: _.sumBy(q.results || [], (r) => r.votesCount || 0),
              // This should always be an empty array (since grid headers can't be responded to)
              // Leaving this transformation here since it already existed
              byChoice: votingChoices_gqlToClient({
                questionType: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
                gqlVotingChoices: q.results ?? [],
                selectLanguageText,
              }),
              // Set all aggregate calculations to null since we aren't loading aggregates
              averageChoiceValueData: null,
              aggregateValue: null,
              trend: null,
              benchmark: null,
            })),
          },
          stats: {
            commentsCount: 0,
            responsesCount:
              (questions || 0) &&
              questions.length &&
              _.sumBy(questions[0].results, (r) => r.votesCount || 0),
            viewsCount: 0,
          },
        },
        typedData: {
          type: QuestionSharedCore.QuestionType.GRID_CHOICE,
          dataDictionary: questions[0].choiceSet.scaleData,
          shareableQuestionChoiceSetId:
            questions[0].choiceSet.sharedChoiceSetData?.id ?? null,
          rows: questions.map((q) => ({
            questionId: q.id,
            label: selectLanguageText(q.title),
            conditions: { ...QuestionSharedCore.DEFAULT_QUESTION_CONDITIONS },
            variableName: q.variableName,
          })),
          columns:
            (questions &&
              questions.length &&
              questions[0].choiceSet.choices.map((c) => ({
                label: selectLanguageText(c.text),
                id: c.id,
                choiceValue: c.choiceValue,
              }))) ||
            [],
        },
        shortId: null,
        optional: gridOptionalityFromGridQuestion(question),
      };
    }

    return null;
  }

  export function gqlToClientWithStatusData_Survey_QuestionNumber(
    question: SurveyQuestionOrGrid | null,
    selectLanguageText: SelectLanguageTextFunction,
    defaultQuestionNumber: number
  ):
    | (QuestionCore.QuestionWithStatusData & {
        readonly questionNumber: number;
      })
    | null {
    if (question === null) return null;
    const statusDataResult = gqlToClientWithStatusData_Survey(
      question,
      selectLanguageText
    );
    if (!statusDataResult) {
      return null;
    }
    return {
      ...statusDataResult,
      questionNumber: question.questionNumber ?? defaultQuestionNumber,
    };
  }

  export function gqlToClientWithAggregateResults_Survey(
    question: SurveyContentsWithAggregateResults,
    publishingEntity: SurveyContentsPublisher,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.QuestionWithAggregateResults | null {
    switch (question.__typename) {
      case 'QuestionHierarchyVisualizationNode':
        return null;
      case 'QuestionHierarchySimulationNode':
        return null;
      case 'Question':
        return gqlToClientWithAggregateResults(
          question,
          publishingEntity,
          selectLanguageText
        );
      case 'QuestionHierarchyParentNode':
        switch (question.structureType) {
          case Gql.QuestionHierarchyParentNodeType.HEADER:
            return null;
          case Gql.QuestionHierarchyParentNodeType.GRID:
            // Grid questions should return aggregate results for all provided rows
            // If any rows are missing aggregate results, we treat all aggregate results for grid as missing (implies aggregates still must be run)
            // If any weighted results are missing, we treat all weights as missing (implies weighting still needs to occur)
            // Neither situation should happen (should always have all or none aggregates, and all or none weights) as a result we don't want to potentially just filter out missing aggregates
            const questions = question.questions || [];

            const questionResults = questions.map((q) => {
              if (!q.aggregateResults) {
                return null;
              }
              const questionResultsCommon = {
                rowId: q.id,
                rowName: selectLanguageText(q.title),
                rowVariableName: q.variableName,
                isBenchmarkedQuestion: q.isBenchmarkedQuestion,
                totalResponses: _.sumBy(
                  q.aggregateResults.choices || [],
                  (c) => c.rawTotal
                ),
                aggregateValue: q.aggregateResults.aggregateValue,
                averageChoiceValueData: maybeMap(
                  questions[0].aggregateResults?.averageChoiceValueData,
                  BenchmarkAggregateTx.gqlToClientAverageChoiceValue
                ),
                trend: maybeMap(
                  q.aggregateResults.trend,
                  BenchmarkAggregateTx.gqlToClientTrendAggregateResult
                ),
                benchmark: q.aggregateResults.benchmark
                  ? {
                      value: BenchmarkAggregateTx.gqlToClientBenchmarkValue(
                        q.aggregateResults.benchmark.value
                      ),
                    }
                  : null,
                commentsWordCount: null,
              };
              const { rawByChoice, weightedByChoice } =
                gqlToClientAggregateResultVotingChoices(
                  q.aggregateResults,
                  selectLanguageText
                );
              return {
                raw: { ...questionResultsCommon, byChoice: rawByChoice },
                weighted: weightedByChoice
                  ? { ...questionResultsCommon, byChoice: weightedByChoice }
                  : null,
              };
            });
            //
            const definedQuestionResults = _.compact(questionResults);
            const hasAggregateResults =
              questionResults.length === definedQuestionResults.length;
            const definedWeightedResults = _.compact(
              definedQuestionResults.map((r) => r.weighted)
            );
            const hasWeightedResults =
              definedWeightedResults.length === definedQuestionResults.length;
            return {
              id: question.id,
              title: selectLanguageText(question.label) ?? 'GRID',
              gridLabel: selectLanguageText(question.label) ?? 'GRID',
              pubName: selectLanguageText(publishingEntity.name), // All questions share same question set
              scaleData:
                questions[0]?.aggregateResults?.scaleData ??
                questions[0]?.choiceSet.scaleData ??
                null, // All grid questions share same choice set
              aggregateResults: !hasAggregateResults
                ? null
                : {
                    raw: {
                      type: QuestionSharedCore.QuestionType.GRID_CHOICE,
                      results: definedQuestionResults.map((result) => result.raw),
                    },
                    weighted: hasWeightedResults
                      ? {
                          type: QuestionSharedCore.QuestionType.GRID_CHOICE,
                          results: definedWeightedResults,
                        }
                      : null,
                  },
              commentsWordCount: null,
            };
        }
    }
  }

  export function gqlToClientWithAggregateResults_Survey_QuestionNumber(
    question: SurveyContentsWithAggregateResults,
    publishingEntity: SurveyContentsPublisher,
    selectLanguageText: SelectLanguageTextFunction,
    defaultQuestionNumber: number
  ):
    | (QuestionCore.QuestionWithAggregateResults & {
        readonly questionNumber: number;
      })
    | null {
    const aggregateResults = gqlToClientWithAggregateResults_Survey(
      question,
      publishingEntity,
      selectLanguageText
    );
    if (!aggregateResults) {
      return null;
    }
    const questionNumber = wrap(() => {
      switch (question.__typename) {
        case 'Question':
        case 'QuestionHierarchyParentNode':
          return question.questionNumber ?? defaultQuestionNumber;
        case 'QuestionHierarchyVisualizationNode':
          return defaultQuestionNumber;
        case 'QuestionHierarchySimulationNode':
          return defaultQuestionNumber;
      }
    });
    return {
      ...aggregateResults,
      questionNumber,
    };
  }

  export function gqlToClientWithAggregateResults(
    question: QuestionWithAggregateResults,
    publishingEntity: SurveyContentsPublisher,
    selectLanguageText: SelectLanguageTextFunction
  ): QuestionCore.QuestionWithAggregateResults | null {
    const questionCommon: Omit<
      QuestionCore.QuestionWithAggregateResults,
      'aggregateResults' | 'type'
    > = {
      id: question.id,
      title: selectLanguageText(question.title),
      gridLabel: selectLanguageText(question.hierarchyParentNode?.label) ?? null,
      pubName: selectLanguageText(publishingEntity.name),
      isBenchmarkedQuestion: question.isBenchmarkedQuestion,
      scaleData: question.choiceSet.scaleData ?? null,
      commentsWordCount: question.commentsWordCount,
    };
    const type = QuestionTx.gqlTypeToClient(question.choiceSet.type);
    switch (type) {
      // Free text questions don't have aggregate results, use normal results
      case QuestionSharedCore.QuestionType.FREE_TEXT:
        const freeTextResult = {
          hasResponses: question.commentsData.totalCount > 0,
          responses: null,
          wordCount: question.commentsWordCount,
        };
        return {
          ...questionCommon,
          gridLabel: null,
          scaleData:
            question.aggregateResults?.scaleData ??
            question.choiceSet.scaleData ??
            null, // Will always be null
          aggregateResults: {
            raw: {
              ...freeTextResult,
              type: QuestionSharedCore.QuestionType.FREE_TEXT,
            },
            weighted: {
              ...freeTextResult,
              type: QuestionSharedCore.QuestionType.FREE_TEXT,
            },
          },
        };
      // Multiple Choice and Point Allocation can directly transform and return aggregate results
      // Some duplicate logic to make TypeScript happy about result types
      case QuestionSharedCore.QuestionType.MULTIPLE_CHOICE:
        if (!question.aggregateResults) {
          return null;
        }
        const mcVotingChoices = gqlToClientAggregateResultVotingChoices(
          question.aggregateResults,
          selectLanguageText
        );

        const mcVotingChoiceCommon: Pick<
          QuestionSharedCore.MultipleChoiceResults,
          | 'hasResponses'
          | 'aggregateValue'
          | 'trend'
          | 'benchmark'
          | 'averageChoiceValueData'
          | 'commentsWordCount'
        > = {
          hasResponses: true,
          aggregateValue: question.aggregateResults.aggregateValue,
          averageChoiceValueData: maybeMap(
            question.aggregateResults.averageChoiceValueData,
            BenchmarkAggregateTx.gqlToClientAverageChoiceValue
          ),
          trend: maybeMap(
            question.aggregateResults.trend,
            BenchmarkAggregateTx.gqlToClientTrendAggregateResult
          ),
          benchmark: question.aggregateResults.benchmark
            ? {
                value: BenchmarkAggregateTx.gqlToClientBenchmarkValue(
                  question.aggregateResults.benchmark.value
                ),
                rank: question.aggregateResults.benchmark.rank,
                totalDataPoints: question.aggregateResults.benchmark.totalDataPoints,
                dateRange: {
                  start: ApiDate.fromApi(
                    question.aggregateResults.benchmark.dateRange.start
                  ),
                  end: ApiDate.fromApi(
                    question.aggregateResults.benchmark.dateRange.end
                  ),
                },
                benchmarkSignificance:
                  question.aggregateResults.benchmark.benchmarkSignificance,
              }
            : null,
          commentsWordCount: question.commentsWordCount,
        };
        return {
          ...questionCommon,
          aggregateResults: {
            raw: {
              ...mcVotingChoiceCommon,
              byChoice: mcVotingChoices.rawByChoice,
              choicesAreScaled: !!question.aggregateResults.scaleData?.scaleType,
              type: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
            },
            weighted:
              mcVotingChoices.weightedByChoice === null
                ? null
                : {
                    ...mcVotingChoiceCommon,
                    choicesAreScaled:
                      !!question.aggregateResults.scaleData?.scaleType,
                    byChoice: mcVotingChoices.weightedByChoice,
                    type: QuestionSharedCore.QuestionType.MULTIPLE_CHOICE,
                  },
          },
        };
      case QuestionSharedCore.QuestionType.POINT_ALLOCATION:
        if (!question.aggregateResults) {
          return null;
        }
        const paVotingChoices = gqlToClientAggregateResultVotingChoices(
          question.aggregateResults,
          selectLanguageText
        );
        const paVotingChoiceCommon = {
          hasResponses: true,
          aggregateValue: question.aggregateResults.aggregateValue,
          averageChoiceValueData: maybeMap(
            question.aggregateResults.averageChoiceValueData,
            BenchmarkAggregateTx.gqlToClientAverageChoiceValue
          ),
          trend: maybeMap(
            question.aggregateResults.trend,
            BenchmarkAggregateTx.gqlToClientTrendAggregateResult
          ),
          benchmark: maybeMap(
            question.aggregateResults.benchmark,
            BenchmarkAggregateTx.gqlToClientBenchmarkAggregateResult
          ),
          commentsWordCount: question.commentsWordCount,
        };
        return {
          ...questionCommon,
          aggregateResults: {
            raw: {
              ...paVotingChoiceCommon,
              byChoice: paVotingChoices.rawByChoice,
              choicesAreScaled: false,
              type: QuestionSharedCore.QuestionType.POINT_ALLOCATION,
            },
            weighted:
              paVotingChoices.weightedByChoice === null
                ? null
                : {
                    ...paVotingChoiceCommon,
                    byChoice: paVotingChoices.weightedByChoice,
                    choicesAreScaled: false,
                    type: QuestionSharedCore.QuestionType.POINT_ALLOCATION,
                  },
          },
        };
      case QuestionSharedCore.QuestionType.GRID_CHOICE:
        // Should not be here
        throw new Error(`Question transformation attempted on grid`);
    }
  }

  export function votingChoices_gqlToClient(args: {
    readonly questionType: QuestionSharedCore.QuestionType;
    readonly gqlVotingChoices: readonly GqlVotingChoice[];
    readonly selectLanguageText: SelectLanguageTextFunction;
  }): readonly QuestionSharedCore.VotingChoice[] {
    const { questionType, gqlVotingChoices, selectLanguageText } = args;

    return gqlVotingChoices.map<QuestionSharedCore.VotingChoice>((ch, i) => ({
      choice: {
        ...ch.choice,
        text: selectLanguageText(ch.choice.text),
      },
      result: ch.result || 0,
      votesCount:
        questionType === QuestionSharedCore.QuestionType.POINT_ALLOCATION
          ? (ch.votesCount ?? 0) * 10
          : ch.votesCount ?? 0,
      identifier: QuestionSharedCore.choiceIdentifier(i),
    }));
  }
}

interface AggregateResultVotingChoices {
  readonly rawByChoice: readonly QuestionSharedCore.VotingChoice[];
  readonly weightedByChoice: readonly QuestionSharedCore.VotingChoice[] | null;
}

function gqlToClientAggregateResultVotingChoices(
  aggregateResults: AggregateResults,
  selectLanguageText: SelectLanguageTextFunction
): AggregateResultVotingChoices {
  const rawSum = _.sumBy(aggregateResults.choices, (ch) => ch.rawTotal);
  const rawByChoice = aggregateResults.choices.map<QuestionSharedCore.VotingChoice>(
    (ch, i) => ({
      choice: {
        ...ch.choice,
        text: selectLanguageText(ch.choice.text),
      },
      result: rawSum ? ch.rawTotal / rawSum : 0,
      identifier: QuestionSharedCore.choiceIdentifier(i),
      votesCount: ch.rawTotal,
    })
  );
  const weightedSum = _.sumBy(
    aggregateResults.choices,
    (ch) => ch.weightedTotal ?? 0
  );
  const weightedByChoice = aggregateResults.choices.some(
    (ch) => ch.weightedTotal === null
  )
    ? null
    : aggregateResults.choices.map<QuestionSharedCore.VotingChoice>((ch, i) => ({
        choice: {
          ...ch.choice,
          text: selectLanguageText(ch.choice.text),
        },
        result: weightedSum ? (ch.weightedTotal ?? 0) / weightedSum : 0,
        identifier: QuestionSharedCore.choiceIdentifier(i),
        votesCount: ch.weightedTotal ?? 0, // All should not be null due to previous check,
      }));
  return {
    rawByChoice,
    weightedByChoice,
  };
}

// Currently all questions use same value, so pull from first one.
// In the future we might change this to allow optionality on individual rows
export function gridOptionalityFromGridQuestion(gridQuestion: {
  readonly questions: readonly { readonly optional: boolean }[];
}) {
  return gridQuestion.questions.length > 0
    ? gridQuestion.questions[0].optional
    : DEFAULT_SURVEY_QUESTION_OPTIONALITY;
}

export function gqlToClient_dataDictionary(
  choiceSet: GqlChoiceSet
): QuestionSharedCore.ChoiceSetDataDictionary | null {
  if (choiceSet.scaleData?.scaleThreshold && choiceSet.scaleData.scaleType) {
    return {
      scaleThreshold: choiceSet.scaleData.scaleThreshold,
      scaleType: choiceSet.scaleData.scaleType,
    };
  }
  return null;
}

export function simulationType_clientToGql(
  simType: SimulationType
): Gql.SimulationNodeType {
  switch (simType) {
    case SimulationType.BUDGET:
      return Gql.SimulationNodeType.BUDGET;
    case SimulationType.HOUSING:
      return Gql.SimulationNodeType.HOUSING;
    case SimulationType.PRIORITIZE:
      return Gql.SimulationNodeType.PRIORITIZE;
    case SimulationType.RECEIPT:
      return Gql.SimulationNodeType.RECEIPT;
  }
}
